import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as IconsSvgScripts } from 'assets/svg/icons.svg';
import { usePages } from 'context/PagesContext';
import Loading from 'components/layouts/Loading';
import { Fragment, useEffect, useState } from 'react';
import DataSaperator from 'components/Infobox/DataSaperator';
import MenuHeader from 'components/layouts/MainMenu/MenuHeader';
import ClickAwayListener from 'react-click-away-listener';
import DropdownBox from 'components/layouts/DropdownBox';
import DropdownItem from 'components/layouts/DropdownBox/DropdownItem';
import { useAuth } from 'context/AuthContext';
import MenuItem from 'components/layouts/MainMenu/MenuItem';
import { render } from 'react-dom';
import TOC from 'components/Editor/TableOfContents/TOC';
import { useGlobal } from 'context/GlobalContext';
import PagePreview from 'components/PagePreview';
import usePagePreview from 'hooks/usePagePreview';

/**
 * TODO
 * refactor logic instead of using login from main page as it is
 * refactoring will save the reads and writes to the firebase
 */
function SharedArticle() {
  const { docId } = useParams();

  const history = useHistory();

  const [dotsMenu, setDotsMenu] = useState(false);

  const { auth } = useAuth();

  const {
    state: { pagePopover },
  } = useGlobal();

  const {
    pages: { activePage, activeInfobox },
    loadInitialPageData,
    duplicatePage,
  } = usePages();

  usePagePreview(docId);

  useEffect(() => {
    loadInitialPageData(docId, history, true);

    setTimeout(() => {
      if (document.querySelector('toc')) {
        render(<TOC />, document.querySelector('toc'));
      }
    }, 1500);
  }, [docId]);

  async function copyPage(e) {
    e.preventDefault();
    if (!auth.isLoggedIn) {
      history.push('/login');
    } else {
      const addedPage = await duplicatePage(
        null,
        activePage,
        auth.user.publicAddress
      );
      setDotsMenu(false);
      // TODO: fix viewdesc.js:1086 Uncaught TypeError:
      // Cannot read property 'destroy' of undefined
      // on route push
      if (addedPage.id) history.push(`/p/${activePage.id}`);
    }
  }

  if (activePage && !activePage.shared) {
    history.push('/page-not-found');
  }

  if (!activePage) {
    return <Loading />;
  }

  return (
    <>
      <IconsSvgScripts />
      <div className='page page-article'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='page-col page-col01'>
              <div className='main-menu'>
                <MenuHeader />
                <div className='nav-category'>
                  <div className='nav-category__content'>
                    <ul className='nav-category__list'>
                      <MenuItem
                        href='#'
                        icon={
                          <svg className='icon icon-cat02'>
                            <use xlinkHref='#icon-cat02'></use>
                          </svg>
                        }
                        active={true}
                        onClick={copyPage}
                      >
                        Clone
                      </MenuItem>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='page-col page-col02'>
              <div className='control'>
                <div className='control-btns'>
                  <ClickAwayListener onClickAway={() => setDotsMenu(false)}>
                    <div className='relative flex items-center'>
                      <button
                        className='btn btn-icon'
                        onClick={() => setDotsMenu(true)}
                      >
                        <svg className='icon icon-dots'>
                          <use xlinkHref='#icon-dots'></use>
                        </svg>
                      </button>
                      <DropdownBox show={dotsMenu}>
                        <DropdownItem onClick={copyPage}>
                          Clone this page
                        </DropdownItem>
                      </DropdownBox>
                    </div>
                  </ClickAwayListener>
                </div>
              </div>
              <div className='content'>
                <div className='content__wrap'>
                  <div className='title-lg'>{activePage.title}</div>
                  {activePage.subTitle && (
                    <div className='subtitle'>{activePage.subTitle}</div>
                  )}

                  <div className='custom-border'></div>
                  <div className='text mt-4 mb-8'>
                    <div
                      className='ProseMirror'
                      dangerouslySetInnerHTML={{ __html: activePage.content }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {activePage?.hasInfoBox && (
              <div className='page-col page-col03'>
                <div
                  className='cover bg-no-repeat bg-center bg-cover'
                  style={{
                    backgroundImage: `linear-gradient(190deg, transparent 60%, rgba(0, 0, 0, 0.6)), url(${activeInfobox?.backgroundUrl}`,
                  }}
                >
                  <div className='cover__info w-full'>
                    <h3 className='cover__title'>{activeInfobox?.title}</h3>
                    <p className='cover__subtitle'>{activeInfobox?.subTitle}</p>
                  </div>
                </div>
                <div className='data'>
                  <div className='data__content'>
                    <div className='data__list'>
                      {activeInfobox?.data.map(item => (
                        <Fragment key={item.id}>
                          {item.type === 'saperator' ? (
                            <DataSaperator />
                          ) : (
                            <div className='data__item flex gap-1.5 group'>
                              <div className='data__title'>{item.title}</div>
                              <div className='data__val'>{item.value}</div>
                            </div>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {pagePopover && <PagePreview />}
      </div>
    </>
  );
}

export default SharedArticle;
