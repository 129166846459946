import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu';
import MenuItem from './MenuItem';
import ItemSaperator from './ItemSaperator';
import { useGlobal } from 'context/GlobalContext';
import { TOGGLE_LINK_MODAL } from 'context/types';
import { ReactComponent as RiH2 } from 'assets/svg/ri-h2.svg';
import { ReactComponent as RiH3 } from 'assets/svg/ri-h3.svg';
import { ReactComponent as RiBold } from 'assets/svg/ri-bold.svg';
import { ReactComponent as RiLink } from 'assets/svg/ri-link.svg';
import { ReactComponent as RiItalic } from 'assets/svg/ri-italic.svg';
import { ReactComponent as RiListOrdered } from 'assets/svg/ri-list-ordered.svg';
import { ReactComponent as RiListUnordered } from 'assets/svg/ri-list-unordered.svg';
import { ReactComponent as RiParagraph } from 'assets/svg/ri-para.svg';
import { ReactComponent as RiDoubleQuotes1 } from 'assets/svg/ri-double-quotes-l.svg';

function BubbleMenu({ editor }) {
  const { dispatch } = useGlobal();

  const menuItems = [
    {
      title: 'Bold',
      icon: <RiBold className='w-5 h-5 fill-current' />,
      active: editor.isActive('bold'),
      onClick: () => editor.chain().focus().toggleBold().run(),
    },

    {
      title: 'Italic',
      icon: <RiItalic className='w-5 h-5 fill-current' />,
      active: editor.isActive('italic'),
      onClick: () => editor.chain().focus().toggleItalic().run(),
    },

    {
      type: 'divider',
    },

    {
      title: 'Heading 2',
      icon: <RiH2 className='w-5 h-5 fill-current' />,
      active: editor.isActive('heading', { level: 2 }),
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    },

    {
      title: 'Heading 3',
      icon: <RiH3 className='w-5 h-5 fill-current' />,
      active: editor.isActive('heading', { level: 3 }),
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    },

    {
      title: 'Paragraph',
      icon: <RiParagraph className='w-5 h-5 fill-current' />,
      active: editor.isActive('paragraph'),
      onClick: () => editor.chain().focus().setParagraph().run(),
    },

    {
      type: 'divider',
    },

    {
      title: 'Bullet List',
      icon: <RiListUnordered className='w-5 h-5 fill-current' />,
      active: editor.isActive('bulletList'),
      onClick: () => editor.chain().focus().toggleBulletList().run(),
    },

    {
      title: 'Order List',
      icon: <RiListOrdered className='w-5 h-5 fill-current' />,
      active: editor.isActive('orderedList'),
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
    },

    {
      title: 'link',
      icon: <RiLink className='w-5 h-5 fill-current' />,
      active: editor.isActive('link'),
      onClick: () => {
        if (editor.isActive('link')) {
          editor.chain().focus().unsetLink().run();
          return;
        }

        dispatch({ type: TOGGLE_LINK_MODAL });
      },
    },

    {
      type: 'divider',
    },

    {
      title: 'Blockquote',
      icon: <RiDoubleQuotes1 className='w-5 h-5 fill-current' />,
      active: editor.isActive('blockquote'),
      onClick: () => editor.chain().focus().toggleBlockquote().run(),
    },
  ];

  return (
    <Menu type='bubble' editor={editor}>
      {menuItems.map((item, index) => (
        <Fragment key={index}>
          {item.type ? (
            <ItemSaperator />
          ) : (
            <MenuItem active={item.active} onClick={item.onClick}>
              {item.icon}
            </MenuItem>
          )}
        </Fragment>
      ))}
    </Menu>
  );
}

BubbleMenu.propTypes = {
  editor: PropTypes.object.isRequired,
};

export default BubbleMenu;
