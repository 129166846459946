function MenuHeader() {
  return (
    <header className='header'>
      <div className='row'>
        <div className='logo'>
          <a href='#' className='logo__link'>
            <img src='/assets/img/logo.svg' alt='logo' />
          </a>
        </div>
      </div>
    </header>
  );
}

export default MenuHeader;
