import { nanoid } from 'utils';
import classNames from 'classnames';
import { arrayMove, List } from 'react-movable';
import DropdownBox from 'components/layouts/DropdownBox';
import DropdownItem from 'components/layouts/DropdownBox/DropdownItem';
import DropdownTitle from 'components/layouts/DropdownBox/DropdownTitle';
import { usePages } from 'context/PagesContext';
import { storageRef } from 'firebase/init';
import { useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import DataItem from './DataItem';
import DataSaperator from './DataSaperator';
import { ReactComponent as SortIcon } from 'assets/svg/sort.svg';
import TextareaAutosize from 'react-textarea-autosize';

function Infobox() {
  const {
    pages: { activeInfobox },
    updateInfoBox,
    deleteInfoBox,
  } = usePages();

  const [showSettings, setShowSettings] = useState(false);
  const [showTitleInput, toggleTitleInput] = useState(false);
  const [showSubTitleInput, toggleSubTitleInput] = useState(false);
  const [title, setTitle] = useState(activeInfobox?.title || '');
  const [subTitle, setSubTitle] = useState(activeInfobox?.subTitle || '');

  const timeout = useRef(null);
  const titleInput = useRef();
  const subTitleInput = useRef();

  useEffect(() => {
    setTitle(activeInfobox?.title || '');
    setSubTitle(activeInfobox?.subTitle || '');
  }, [activeInfobox]);

  useEffect(() => {
    titleInput.current && titleInput.current.focus();
    subTitleInput.current && subTitleInput.current.focus();

    titleInput.current &&
      titleInput.current.setSelectionRange(
        titleInput.current.value.length,
        titleInput.current.value.length
      );

    subTitleInput.current &&
      subTitleInput.current.setSelectionRange(
        subTitleInput.current.value.length,
        subTitleInput.current.value.length
      );
  }, [showTitleInput, showSubTitleInput]);

  const updateBoxData = (data = {}) => {
    if (!title.trim() || !subTitle.trim()) return;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      const newBoxData = {
        ...activeInfobox,
        ...data,
      };

      updateInfoBox(newBoxData);
    }, 1000);
  };

  const delItem = id => {
    const newBoxData = {
      ...activeInfobox,
      data: activeInfobox.data.filter(d => d.id !== id),
    };

    updateInfoBox(newBoxData);
  };

  const updateItem = (id, data = {}) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      const newBoxData = {
        ...activeInfobox,
        data: activeInfobox.data.map(d => {
          if (d.id === id) {
            return {
              ...d,
              ...data,
            };
          }
          return d;
        }),
      };

      updateInfoBox(newBoxData);
    }, 1000);
  };

  const addItemRow = (options = {}) => {
    const { type, position } = options;

    const newBoxData = {
      ...activeInfobox,
      data: [...activeInfobox.data],
    };

    if (type === 'saperator') {
      if (newBoxData.data.reverse()[0].type === 'saperator') return;

      newBoxData.data = [
        ...activeInfobox.data,
        {
          id: nanoid(),
          type: 'saperator',
        },
      ];
    }

    if (type === 'item') {
      const newItem = {
        id: nanoid(),
        title: 'Item Title',
        value: 'Click on title or value to edit',
        type: 'item',
      };

      if (position === 'above') {
        newBoxData.data = [newItem, ...activeInfobox.data];
      } else {
        newBoxData.data = [...activeInfobox.data, newItem];
      }
    }

    updateInfoBox(newBoxData);
  };

  function changeItemPosition(changedData) {
    const newBoxData = {
      ...activeInfobox,
      data: changedData,
    };

    updateInfoBox(newBoxData, { async: true });
  }

  // TODO: Refactior and make reusable functions later;
  async function changeFile(e) {
    if (!e.target.files.length) {
      setShowSettings(false);
      return;
    }

    const allowedTypes = ['jpeg', 'jpg', 'png', 'bmp', 'gif'];

    const file = e.target.files[0];
    const type = file.type.split('/').pop().toLowerCase();

    if (!allowedTypes.includes(type)) {
      setShowSettings(false);
      alert('Please select a valid image file.');
      return;
    }

    setShowSettings(false);

    const url = await uploadFile(file);
    e.target.value = '';

    const newBoxData = {
      ...activeInfobox,
      backgroundUrl: url,
    };

    updateInfoBox(newBoxData);
  }

  async function uploadFile(file) {
    const ext = file.name.split('.').reverse()[0];
    const name = nanoid();

    const uploadFileRef = storageRef.child(`uploads/${name}.${ext}`);
    await uploadFileRef.put(file);
    const url = await uploadFileRef.getDownloadURL();

    return url;
  }

  return (
    <>
      <div
        className='cover bg-no-repeat bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(190deg, transparent 50%, rgba(0, 0, 0, 0.85)), url(${activeInfobox?.backgroundUrl}`,
        }}
      >
        <ClickAwayListener onClickAway={() => setShowSettings(false)}>
          <div className='dropdown-setting'>
            <button
              className='btn-dropdown btn btn-round'
              onClick={() => setShowSettings(!showSettings)}
            >
              <svg className='icon icon-setting'>
                <use xlinkHref='#icon-setting'></use>
              </svg>
            </button>
            <DropdownBox show={showSettings}>
              <DropdownTitle>Settings</DropdownTitle>
              <DropdownItem
                href='#'
                onClick={e => {
                  e.preventDefault();
                  addItemRow({ type: 'item', position: 'above' });
                  setShowSettings(false);
                }}
              >
                <svg className='icon icon-spacing'>
                  <use xlinkHref='#icon-spacing'></use>
                </svg>{' '}
                Add row above
              </DropdownItem>
              <DropdownItem
                href='#'
                onClick={e => {
                  e.preventDefault();
                  addItemRow({ type: 'item', position: 'below' });
                  setShowSettings(false);
                }}
              >
                <svg className='icon icon-spacing02'>
                  <use xlinkHref='#icon-spacing02'></use>
                </svg>{' '}
                Add row below
              </DropdownItem>
              <DropdownItem
                href='#'
                onClick={e => {
                  e.preventDefault();
                  addItemRow({ type: 'saperator' });
                  setShowSettings(false);
                }}
              >
                <svg className='icon icon-spacing02'>
                  <use xlinkHref='#icon-spacing02'></use>
                </svg>{' '}
                Add divider
              </DropdownItem>
              <DropdownItem
                href='#'
                style={{ marginTop: '10px' }}
                onClick={e => {
                  e.preventDefault();
                  toggleTitleInput(true);
                  setShowSettings(false);
                }}
              >
                <svg className='icon icon-caps'>
                  <use xlinkHref='#icon-caps'></use>
                </svg>{' '}
                Edit title
              </DropdownItem>
              <DropdownItem
                href='#'
                onClick={e => {
                  e.preventDefault();
                  toggleSubTitleInput(true);
                  setShowSettings(false);
                }}
              >
                <svg className='icon icon-caps'>
                  <use xlinkHref='#icon-caps'></use>
                </svg>{' '}
                Edit subtitle
              </DropdownItem>
              <DropdownItem href='#' style={{ marginTop: '10px' }}>
                <label className='dropdown-box__item p-0 cursor-pointer'>
                  <svg className='icon icon-img02'>
                    <use xlinkHref='#icon-img02'></use>
                  </svg>{' '}
                  <input
                    type='file'
                    name='file'
                    className='hidden'
                    onChange={changeFile}
                  />
                  Change image
                </label>
              </DropdownItem>
              <span className='dropdown-box__line'></span>
              <DropdownItem
                href='#'
                onClick={e => {
                  e.preventDefault();
                  deleteInfoBox();
                  setShowSettings(false);
                }}
              >
                <svg className='icon icon-del02'>
                  <use xlinkHref='#icon-del02'></use>
                </svg>{' '}
                Delete infobox
              </DropdownItem>
            </DropdownBox>
          </div>
        </ClickAwayListener>

        <div className='cover__info w-full'>
          {!showTitleInput ? (
            <h3 className='cover__title cursor-text' onClick={() => toggleTitleInput(true)}>
              {activeInfobox?.title}
            </h3>
          ) : (
            <ClickAwayListener onClickAway={() => toggleTitleInput(false)}>
              <form
                className='flex'
                onSubmit={e => {
                  e.preventDefault();
                  updateBoxData({ title });
                  toggleTitleInput(false);
                }}
              >
                <TextareaAutosize
                  className='cover__title w-full h-full bg-transparent mt-0 resize-none overflow-hidden'
                  value={title}
                  ref={ref => (titleInput.current = ref)}
                  placeholder='Enter Title'
                  required
                  onChange={e => {
                    setTitle(e.target.value);
                    if (e.target.value.trim()) {
                      updateBoxData({ title: e.target.value });
                    }
                  }}
                  onKeyDown={e => e.key === 'Escape' && toggleTitleInput(false)}
                />
              </form>
            </ClickAwayListener>
          )}
          {!showSubTitleInput ? (
            <p
              className='cover__subtitle cursor-text'
              onClick={() => toggleSubTitleInput(true)}
            >
              {activeInfobox?.subTitle}
            </p>
          ) : (
            <ClickAwayListener onClickAway={() => toggleSubTitleInput(false)}>
              <form
                className='flex'
                onSubmit={e => {
                  e.preventDefault();
                  updateBoxData({ subTitle });
                  toggleSubTitleInput(false);
                }}
              >
                <TextareaAutosize
                  className='cover__subtitle w-full h-full bg-transparent resize-none overflow-hidden'
                  value={subTitle}
                  ref={ref => (subTitleInput.current = ref)}
                  placeholder='Enter Sub Title'
                  required
                  onChange={e => {
                    setSubTitle(e.target.value);
                    if (e.target.value.trim()) {
                      updateBoxData({ subTitle: e.target.value });
                    }
                  }}
                  onKeyDown={e =>
                    e.key === 'Escape' && toggleSubTitleInput(false)
                  }
                />
              </form>
            </ClickAwayListener>
          )}
        </div>
      </div>

      <div className='data pl-0' tabIndex='0'>
        <div className='data__content'>
          {activeInfobox && (
            <List
              values={activeInfobox.data}
              lockVertically={true}
              onChange={({ oldIndex, newIndex }) =>
                changeItemPosition(
                  arrayMove(activeInfobox.data, oldIndex, newIndex)
                )
              }
              renderList={({ children, props }) => (
                <div {...props} className='data__list'>
                  {children}
                </div>
              )}
              renderItem={({ value, props, isDragged }) => {
                const isSaperator = value.type === 'saperator';

                return (
                  <div
                    className={classNames([
                      'data__item flex gap-1.5 group',
                      isSaperator && 'py-0',
                      isDragged ? 'cursor-grabbing' : 'cursor-grab',
                    ])}
                    {...props}
                  >
                    {isSaperator ? (
                      <>
                        <span
                          className='text-white ml-[3px] self-center invisible group-hover:visible'
                          style={{ height: 'max-content' }}
                        >
                          <SortIcon className='w-[14px] fill-current' />
                        </span>
                        <DataSaperator className='flex-grow' />
                        <a
                          className='data_icon hidden h-full cursor-pointer group-hover:inline-block p-1 rounded-full hover:bg-dark'
                          onClick={() => {
                            delItem(value.id);
                          }}
                        >
                          <svg className='icon icon-plus02 w-3 h-3 transform rotate-45 fill-current'>
                            <use xlinkHref='#icon-plus02'></use>
                          </svg>
                        </a>
                      </>
                    ) : (
                      <DataItem
                        title={value.title}
                        value={value.value}
                        itemId={value.id}
                        delItem={delItem}
                        updateItem={updateItem}
                      />
                    )}
                  </div>
                );
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Infobox;
