import classNames from 'classnames';
import PropTypes from 'prop-types';

function DataSaperator({ className }) {
  return (
    <hr
      className={classNames([
        'h-0 border-t-1 border-[#353639] my-2.5',
        className,
      ])}
    />
  );
}

DataSaperator.propTypes = {
  className: PropTypes.string,
};

export default DataSaperator;
