import { useGlobal } from 'context/GlobalContext';
import { useEffect } from 'react';
import { closePagePreview, openPagePreview } from 'utils/page-preview';

function usePagePreview(docId) {
  const { dispatch } = useGlobal();

  useEffect(() => {
    setTimeout(() => {
      const allLinks = document.querySelectorAll('.ProseMirror a');
      attatchEvents(allLinks);
    }, 3000);

    return () => {
      const allLinks = document.querySelectorAll('.ProseMirror a');
      removeEvents(allLinks);
    };
  }, [docId]);

  function attatchEvents(allLinks) {
    [...allLinks].forEach(linkItem => {
      if (!linkItem.closest('.contents')) {
        linkItem.addEventListener('mouseover', showPagePopup);
        linkItem.addEventListener('mouseout', hidePagePopup);
      }
    });
  }

  function removeEvents(allLinks) {
    [...allLinks].forEach(linkItem => {
      if (!linkItem.closest('.contents')) {
        linkItem.removeEventListener('mouseover', showPagePopup);
        linkItem.removeEventListener('mouseout', hidePagePopup);
      }
    });
  }

  function showPagePopup(e) {
    openPagePreview(e, dispatch);
  }

  function hidePagePopup() {
    closePagePreview(dispatch);
  }

  return null;
}

export default usePagePreview;
