import classNames from 'classnames';
import { useGlobal } from 'context/GlobalContext';
import { useEffect, useState } from 'react';
import { usePages } from 'context/PagesContext';
import { ADD_FETCHED_PAGE, SET_PAGE_POPUP } from 'context/types';

function PagePreview() {
  const {
    state: { popupInfo, fetchedPopupPages, previewTimeout },
    dispatch,
    fetchPopupPage,
    clearPreviewTimeout,
  } = useGlobal();

  const {
    pages: { allPages },
    getInfoBox,
  } = usePages();

  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [page, setPage] = useState({});
  const [infoBox, setInfoBox] = useState({});

  useEffect(() => {
    if (!popupInfo) return;

    const { popupPos, pageId } = popupInfo;

    const left =
      Math.floor(popupPos.left) + Math.floor(popupPos.width) + 15 + 'px';
    const top = Math.floor(popupPos.top) + 10 + 'px';
    setLeft(left);
    setTop(top);

    let popupPage = fetchedPopupPages.find(p => p.id === pageId);
    if (popupPage) {
      setPopupPage(popupPage);
      return;
    }

    popupPage = allPages.find(p => p.id === pageId);
    if (popupPage) {
      dispatch({ type: ADD_FETCHED_PAGE, payload: popupPage });
      return;
    }

    fetchPopupPage(pageId);
  }, [fetchedPopupPages]);

  function setPopupPage(page) {
    setPage(page);

    if (page?.hasInfoBox) {
      getInfoBox(page?.id).then(infobox => setInfoBox(infobox));
    }
  }

  if (!Object.keys(page).length) return null;

  return (
    <div
      className={classNames(['article-popup', !top && 'hidden'])}
      style={{ left, top }}
      onMouseEnter={() => {
        if (previewTimeout) {
          clearPreviewTimeout();
        }
      }}
      onMouseLeave={() => dispatch({ type: SET_PAGE_POPUP, payload: false })}
    >
      {infoBox?.backgroundUrl && (
        <div
          className='article-popup__img'
          style={{ backgroundImage: `url("${infoBox?.backgroundUrl}")` }}
        ></div>
      )}
      <div className='article-popup__body'>
        <h3 className='article-popup__title'>{page?.title}</h3>
        <div
          className='article-popup__text'
          dangerouslySetInnerHTML={{ __html: page?.content }}
        ></div>
        <span className='btn article-popup__btn hidden'>
          <svg className='icon icon-arrow-right'>
            <use xlinkHref='#icon-arrow-right'></use>
          </svg>
        </span>
      </div>
      <span className='arrow'></span>
      <a
        href={popupInfo.pupupLink}
        className='btn article-popup__btn'
        rel='noreferrer'
        target='_blank'
      >
        <svg className='icon icon-arrow-right'>
          <use xlinkHref='#icon-arrow-right'></use>
        </svg>
      </a>
    </div>
  );
}

export default PagePreview;
