import { Link } from 'react-router-dom';
import HomeNavBar from 'components/layouts/HomeNavBar';

function Home() {
  return (
    <div className='font-body bg-black min-h-screen flex items-center justify-center'>
      <HomeNavBar />
      <div className='flex flex-col justify-center items-center mt-24'>
        <h1 className='text-center text-white text-[100px] leading-[113%] mb-7 font-extrabold'>
          A tool for <br />
          <span
            className='font-extrabold text-[100px] leading-[113%] text-[#fe6c66] bg-heading-gradient-wk bg-heading-gradient bg-clip-text'
            style={{ WebkitTextFillColor: 'transparent' }}
          >
            Worldbuilding
          </span>
        </h1>
        <p className='max-w-[550px] opacity-75 text-[26px] leading-[178%] text-center mb-9'>
          For writers and gamebuilders to create their world.
        </p>
        <Link
          to='/login'
          className='bg-white text-black py-3.5 px-8 rounded-full font-semibold text-lg transform transition duration-300 hover:translate-y-[-3px] hover:shadow-sky-blue'
        >
          Get Started
        </Link>
      </div>
    </div>
  );
}

export default Home;
