import { nanoid } from 'nanoid';

export function updateHeadings(editor) {
  const allHeadings = [];
  const transaction = editor.state.tr;

  editor.state.doc.descendants((node, pos) => {
    if (node.type.name === 'heading') {
      const id = nanoid(6);
      const bookmark = node.attrs.id || `heading-${id}`;

      if (node.attrs.id !== bookmark) {
        transaction.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          id: bookmark,
        });
      }

      const parentHeading = [...allHeadings]
        .reverse()
        .find(h => h.level < node.attrs.level);

      const heading = {
        id,
        bookmark,
        level: node.attrs.level,
        text: node.textContent,
        parentId: parentHeading ? parentHeading.id : null,
      };

      !!node.textContent.trim() && allHeadings.push(heading);
    }
  });

  transaction.setMeta('preventUpdate', true);

  editor.view.dispatch(transaction);

  return allHeadings;
}

export function buildTocHeadings() {
  const headingLevels = {
    H1: 1,
    H2: 2,
    H3: 3,
    H4: 4,
    H5: 5,
    H6: 6,
  };
  const headingTags = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

  const allHeadings = [];
  const elements = document.querySelectorAll('.ProseMirror *');

  [...elements].forEach(node => {
    if (headingTags.includes(node.tagName)) {
      const parentHeading = [...allHeadings]
        .reverse()
        .find(h => h.level < headingLevels[node.tagName]);

      const heading = {
        id: node.id,
        bookmark: node.id,
        level: headingLevels[node.tagName],
        text: node.textContent,
        parentId: parentHeading?.id || null,
      };

      !!node.textContent.trim() && allHeadings.push(heading);
    }
  });

  return allHeadings;
}
