import classNames from 'classnames';
import DropdownBox from 'components/layouts/DropdownBox';
import DropdownItem from 'components/layouts/DropdownBox/DropdownItem';
import DropdownTitle from 'components/layouts/DropdownBox/DropdownTitle';
import { useFolders } from 'context/FoldersContext';
import { useGlobal } from 'context/GlobalContext';
import { usePages } from 'context/PagesContext';
import { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useHistory } from 'react-router-dom';
import FolderItemDropDown from './FolderItemDropDown';
import FolderMenu from './FolderMenu';
import FolderMenuItem from './FolderMenuItem';
import PageItemDropDown from './PageItemDropDown';
import PageMenu from './PageMenu';
import PageMenuItem from './PageMenuItem';

function MainSidebar() {
  const {
    folders: { activeFolder, allFolders },
    createNewFolder,
    renameFolder,
    deleteFolder,
  } = useFolders();

  const {
    pages: { activePage, allPages },
    addPage,
    deletePage,
  } = usePages();

  const {
    state: { showSidebar },
  } = useGlobal();

  const history = useHistory();

  const [openMainDropdown, setOpenMainDropdown] = useState(false);
  const [folderOption, setFolderOption] = useState({
    pageMenu: null,
    folderMenu: null,
    editFolderId: null,
  });

  const setFolderOptions = options => {
    setFolderOption(o => ({
      ...o,
      ...options,
    }));
  };

  const getPages = folderId => {
    const pages = allPages.filter(p => p.parentFolder === folderId);
    return pages;
  };

  const handleRenameFolder = (e, id) => {
    e.preventDefault();
    const name = e.target.querySelector('input').value.trim();
    if (!name) return;
    renameFolder(id, name);
    setFolderOptions({ editFolderId: null });
  };

  const delFolder = folderId => {
    deleteFolder(folderId);

    const folderPages = allPages.filter(p => p.parentFolder === folderId);

    for (const page of folderPages) {
      deletePage(page.id);
    }

    if (activePage.parentFolder === folderId) {
      const otherPages = allPages.filter(p => p.parentFolder !== folderId);
      const redirect = (otherPages.length && otherPages[0]) || null;

      redirect && history.push(`/p/${redirect.id}`);
    }
  };

  return (
    <div className={classNames(['main-sidebar', showSidebar && 'show'])}>
      <div className='main-sidebar__head'>
        <h3 className='header__title'>Pages</h3>
        <ClickAwayListener onClickAway={() => setOpenMainDropdown(false)}>
          <div className='relative'>
            <button
              className='btn btn-round'
              onClick={() => setOpenMainDropdown(!openMainDropdown)}
            >
              <svg className='icon icon-plus'>
                <use xlinkHref='#icon-plus'></use>
              </svg>
            </button>
            <DropdownBox
              show={openMainDropdown}
              style={{ top: '45px', right: 0, maxWidth: '180px' }}
            >
              <DropdownTitle className='mt-2'>Add...</DropdownTitle>
              <DropdownItem
                onClick={e => {
                  e.preventDefault();
                  createNewFolder();
                  setOpenMainDropdown(false);
                }}
              >
                <svg className='icon icon-folder'>
                  <use xlinkHref='#icon-folder'></use>
                </svg>{' '}
                New Folder
              </DropdownItem>
              <DropdownItem
                onClick={e => {
                  e.preventDefault();
                  addPage(null, history);
                  setOpenMainDropdown(false);
                }}
              >
                <svg className='icon icon-document'>
                  <use xlinkHref='#icon-document'></use>
                </svg>{' '}
                New Page
              </DropdownItem>
            </DropdownBox>
          </div>
        </ClickAwayListener>
      </div>
      <div className='main-sidebar__menu'>
        <div className='page-nav'>
          <div className='nav'>
            <FolderMenu>
              {allFolders.map(folder => (
                <FolderMenuItem
                  active={folder.id === activeFolder?.id}
                  key={folder.id}
                  onClick={e => {
                    e.preventDefault();
                    e.target.parentElement.classList.toggle('nav__item_active');
                    e.target
                      .closest('li')
                      .querySelector('.nav-submenu')
                      ?.classList.toggle('in');
                  }}
                  onMouseLeave={() => setFolderOptions({ folderMenu: null })}
                >
                  {folderOption.editFolderId === folder.id ? (
                    <form
                      className='w-full'
                      onSubmit={e => {
                        e.preventDefault();
                        handleRenameFolder(e, folder.id);
                      }}
                    >
                      <input
                        className='w-full rounded-sm text-gray-800 p-1'
                        type='text'
                        required
                        onKeyDown={e =>
                          e.key === 'Escape' &&
                          setFolderOptions({ editFolderId: null })
                        }
                      />
                    </form>
                  ) : (
                    folder.name
                  )}
                  <FolderMenuItem.DropDown>
                    <FolderItemDropDown
                      folder={folder}
                      folderOption={folderOption}
                      addPage={addPage}
                      delFolder={delFolder}
                      setFolderOptions={setFolderOption}
                    />
                  </FolderMenuItem.DropDown>
                  {!!getPages(folder.id).length && (
                    <FolderMenuItem.SubMenu>
                      <PageMenu show={folder.id === activeFolder?.id}>
                        {getPages(folder.id).map(page => (
                          <PageMenuItem
                            key={page.id}
                            href={`/p/${page.id}`}
                            active={page.id === activePage?.id}
                            onMouseLeave={() =>
                              setFolderOptions({ pageMenu: null })
                            }
                          >
                            {page.title}
                            <PageMenuItem.DropDown>
                              <PageItemDropDown
                                page={page}
                                folderOption={folderOption}
                                deletePage={deletePage}
                                setFolderOptions={setFolderOptions}
                              />
                            </PageMenuItem.DropDown>
                          </PageMenuItem>
                        ))}
                      </PageMenu>
                    </FolderMenuItem.SubMenu>
                  )}
                </FolderMenuItem>
              ))}
              {/* to display the pages at root level */}
              {getPages(null).map(page => (
                <PageMenuItem
                  className='pl-[22px]'
                  key={page.id}
                  href={`/p/${page.id}`}
                  active={page.id === activePage?.id}
                  onMouseLeave={() => setFolderOptions({ pageMenu: null })}
                >
                  {page.title}
                  <PageMenuItem.DropDown>
                    <PageItemDropDown
                      page={page}
                      folderOption={folderOption}
                      deletePage={deletePage}
                      setFolderOptions={setFolderOptions}
                    />
                  </PageMenuItem.DropDown>
                </PageMenuItem>
              ))}
            </FolderMenu>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainSidebar;
