import PropTypes from 'prop-types';
import { useAuth } from 'context/AuthContext';
import { Route, Redirect } from 'react-router-dom';
import Loading from 'components/layouts/Loading';

const PrivateRoute = ({ children, ...rest }) => {
  const {
    auth: { isLoggedIn, loadingUser },
  } = useAuth();

  if (loadingUser) {
    return <Loading />;
  }

  return isLoggedIn ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to='/login' />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
