// Global Context
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_LINK_MODAL = 'TOGGLE_LINK_MODAL';
export const SHOW_LINK_MODAL = 'SHOW_LINK_MODAL';
export const HIDE_LINK_MODAL = 'HIDE_LINK_MODAL';
export const TOGGLE_PAGE_POPUP = 'TOGGLE_PAGE_POPUP';
export const SET_PAGE_POPUP = 'SET_PAGE_POPUP';
export const SET_POPUP_INFO = 'SET_POPUP_INFO';
export const CLEAR_POPUP = 'CLEAR_POPUP';
export const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL';
export const TOGGLE_SEARCH_MODAL = 'TOGGLE_SEARCH_MODAL';
export const SET_AUTO_SAVING = 'SET_AUTO_SAVING';
export const ADD_FETCHED_PAGE = 'ADD_FETCHED_PAGE';
export const SET_PAGE_PREVIEW_TIMEOUT = 'SET_PAGE_PREVIEW_TIMEOUT';
