import classNames from 'classnames';
import PropTypes from 'prop-types';

const SubMenu = () => null;
const DropDown = () => null;

function FolderMenuItem({ children, active, ...rest }) {
  let text = children;
  let submenu = null;
  let dropdown = null;
  let form = null;

  if (Array.isArray(children)) {
    text = children.find(child => !child.type);
    submenu = children.find(child => child.type === SubMenu)?.props.children;
    dropdown = children.find(child => child.type === DropDown)?.props.children;
    form = children.find(child => child.type === 'form');
  }

  return (
    <li
      className={classNames(['nav__item', active && 'nav__item_active'])}
      {...rest}
    >
      <button className='nav__link w-full'>
        <svg className='icon icon-caret'>
          <use xlinkHref='#icon-caret'></use>
        </svg>
        <svg className='icon icon-folder'>
          <use xlinkHref='#icon-folder'></use>
        </svg>
        <svg className='icon icon-folder02'>
          <use xlinkHref='#icon-folder02'></use>
        </svg>
        {text}
        {form}
      </button>
      {dropdown}
      {submenu}
    </li>
  );
}

FolderMenuItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FolderMenuItem.defaultProps = {
  active: false,
};

FolderMenuItem.SubMenu = SubMenu;
FolderMenuItem.DropDown = DropDown;

export default FolderMenuItem;
