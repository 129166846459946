function Loading() {
  return (
    <div className='preloader'>
      <div>
        <img src='/assets/img/logo-small.svg' alt='logo' />
        <div className='loading-bar'>
          <div className='progress-bar'></div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
