import Home from 'pages/Home';
import { Route, Switch } from 'react-router';
import Error from 'components/Error';
import Login from 'pages/Login';
import Article from 'pages/Article';
import PrivateRoute from 'routes/PrivateRoute';
import SharedArticle from 'pages/SharedArticle';
import { Redirect } from 'react-router-dom';

function Routes() {
  return (
    <Switch>
      <Route exact path='/'>
        <Home />
      </Route>
      <Route exact path='/login'>
        <Login />
      </Route>
      <PrivateRoute exact path='/oauth/callback'>
        <Redirect to='/' />
      </PrivateRoute>
      <PrivateRoute exact path='/p/:docId'>
        <Article />
      </PrivateRoute>
      <Route exact path='/page/:docId'>
        <SharedArticle />
      </Route>
      <Route path='*'>
        <Error code={404} message='Page Not Found' />
      </Route>
    </Switch>
  );
}

export default Routes;
