import { db } from 'firebase/init';
import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';
import {
  CLEAR_POPUP,
  SET_PAGE_POPUP,
  TOGGLE_LINK_MODAL,
  TOGGLE_SHARE_MODAL,
  TOGGLE_SIDEBAR,
  TOGGLE_SEARCH_MODAL,
  SET_AUTO_SAVING,
  SET_POPUP_INFO,
  ADD_FETCHED_PAGE,
  SET_PAGE_PREVIEW_TIMEOUT,
} from './types';

const GlobalContext = createContext();

export function useGlobal() {
  return useContext(GlobalContext);
}

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_SIDEBAR:
      localStorage.setItem('showSidebar', JSON.stringify(!state.showSidebar));
      return {
        ...state,
        showSidebar: !state.showSidebar,
      };
    case TOGGLE_LINK_MODAL:
      return {
        ...state,
        openLinkModal: !state.openLinkModal,
      };
    case TOGGLE_SHARE_MODAL:
      return {
        ...state,
        openShareModal: !state.openShareModal,
      };
    case TOGGLE_SEARCH_MODAL:
      return {
        ...state,
        searchModal: !state.searchModal,
      };
    case SET_PAGE_POPUP:
      return {
        ...state,
        pagePopover: payload,
      };
    case SET_PAGE_PREVIEW_TIMEOUT:
      return {
        ...state,
        previewTimeout: payload,
      };
    case SET_POPUP_INFO:
      return {
        ...state,
        popupInfo: {
          ...payload,
        },
      };
    case SET_AUTO_SAVING:
      return {
        ...state,
        autoSaving: payload,
      };
    case CLEAR_POPUP:
      return {
        ...state,
        pagePopover: false,
        popupInfo: null,
      };
    case ADD_FETCHED_PAGE:
      return {
        ...state,
        fetchedPopupPages: [...state.fetchedPopupPages, payload],
      };
    default:
      state;
  }
}

const sideBar = JSON.parse(localStorage.getItem('showSidebar'));

const initialState = {
  showSidebar: sideBar !== null ? sideBar : true,
  openLinkModal: false,
  openShareModal: false,
  pagePopover: false,
  previewTimeout: null,
  popupInfo: null,
  searchModal: false,
  autoSaving: false,
  fetchedPopupPages: [],
};

export function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function fetchPopupPage(pageId) {
    if (!pageId) return;

    try {
      const page = await db.collection('pages').doc(pageId).get();
      const data = page.data();

      if (!data) return;

      dispatch({
        type: ADD_FETCHED_PAGE,
        payload: {
          id: page.id,
          ...data,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  function clearPreviewTimeout() {
    clearTimeout(state.previewTimeout);
    dispatch({ type: SET_PAGE_PREVIEW_TIMEOUT, payload: null });
  }

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
        fetchPopupPage,
        clearPreviewTimeout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
