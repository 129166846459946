import { useGlobal } from 'context/GlobalContext';
import classNames from 'classnames';
import { TOGGLE_SEARCH_MODAL } from 'context/types';
import ClickAwayListener from 'react-click-away-listener';
import { useEffect, useRef, useState } from 'react';
import { usePages } from 'context/PagesContext';
import { useFolders } from 'context/FoldersContext';
import { Link } from 'react-router-dom';

function SearchModal() {
  const {
    state: { searchModal },
    dispatch,
  } = useGlobal();

  const {
    pages: { allPages },
  } = usePages();

  const {
    folders: { allFolders },
  } = useFolders();

  const [search, setSearch] = useState('');
  const [matchedPages, setMatchedPages] = useState(allPages);
  const [showPlaceholder, togglePlaceholder] = useState(false);
  const searchInputRef = useRef();

  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  const getFolderName = folderId => {
    const folder = allFolders.find(f => f.id === folderId);
    return folder ? folder.name : '';
  };

  const searchPage = e => {
    const value = e.target.value.trim();

    setSearch(e.target.value);

    if (value) {
      const foundPages = allPages.filter(page => {
        if (page.title.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
          return true;
        } else {
          return false;
        }
      });
      setMatchedPages(foundPages);
    } else {
      setMatchedPages(allPages);
    }
  };

  return (
    <div
      className={classNames([
        searchModal && 'in',
        'chat-popup chat-popup-search chat-popup-remind',
      ])}
    >
      <div className='chat-popup-wrap'>
        <ClickAwayListener
          onClickAway={() => dispatch({ type: TOGGLE_SEARCH_MODAL })}
        >
          <div className='chat-popup-box'>
            <div className='chat-remind-head'>
              <h3 className='popup-title'>Search</h3>
              <button
                className='chat-popup-close'
                onClick={() => dispatch({ type: TOGGLE_SEARCH_MODAL })}
              >
                <svg className='icon icon-close'>
                  <use xlinkHref='#icon-close'></use>
                </svg>
              </button>
            </div>

            <div className='search-input-box'>
              <div
                className={classNames([
                  'search-placeholder',
                  showPlaceholder && 'active',
                ])}
                onClick={() => {
                  togglePlaceholder(false);
                  setTimeout(() => {
                    searchInputRef.current.focus();
                  }, 150);
                }}
              >
                <svg className='icon icon-search'>
                  <use xlinkHref='#icon-search'></use>
                </svg>
                <span className='search-placeholder-text'>Search</span>
              </div>
              <input
                type='text'
                name='search'
                className={classNames([
                  'search-input',
                  !showPlaceholder && '!inline-block',
                ])}
                ref={searchInputRef}
                value={search}
                onBlur={e => {
                  if (!e.target.value.trim()) {
                    togglePlaceholder(true);
                  }
                }}
                onChange={searchPage}
              />
            </div>

            <div className='chat-remind-body'>
              <ul className='chat-remind-list'>
                {matchedPages.map(page => (
                  <li
                    key={page.id}
                    onClick={() => dispatch({ type: TOGGLE_SEARCH_MODAL })}
                  >
                    <Link to={`/p/${page.id}`}>
                      <span className='date-text'>{page.title}</span>
                      <span className='date-num'>
                        {getFolderName(page.parentFolder)}
                      </span>
                    </Link>
                  </li>
                ))}
                {!matchedPages.length && (
                  <li>
                    <a href='#'>
                      <span className='date-text'>Page not found.</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
}

export default SearchModal;
