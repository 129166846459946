import classNames from 'classnames';
import PropTypes from 'prop-types';

function DropdownItem({ href, children, className, ...rest }) {
  return (
    <a
      href={href}
      className={classNames(['dropdown-box__item', className])}
      {...rest}
    >
      {children}
    </a>
  );
}

DropdownItem.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

DropdownItem.defaultProps = {
  href: '#',
};

export default DropdownItem;
