import MainMenu from 'components/layouts/MainMenu';
import MainSidebar from 'components/MainSidebar';
import { ReactComponent as IconsSvgScripts } from 'assets/svg/icons.svg';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useFolders } from 'context/FoldersContext';
import { usePages } from 'context/PagesContext';
import { useContext, useEffect, useRef, useState } from 'react';
import Infobox from 'components/Infobox';
import PageControls from 'components/PageControls';
import Editor from 'components/Editor';
import EditorContext from 'context/EditorContext';
import LinkModal from 'components/Modals/LinkModal';
import { useGlobal } from 'context/GlobalContext';
import PagePreview from 'components/PagePreview';
import ShareModal from 'components/Modals/ShareModal';
import SearchModal from 'components/Modals/SearchModal';
import { SET_AUTO_SAVING } from 'context/types';
import usePagePreview from 'hooks/usePagePreview';

function Article() {
  const { docId } = useParams();

  const history = useHistory();

  const {
    folders: { allFolders },
    setActiveFolder,
  } = useFolders();

  const {
    pages: { activePage },
    loadInitialPageData,
    savePageContent,
  } = usePages();

  const {
    state: {
      openLinkModal,
      pagePopover,
      openShareModal,
      searchModal,
      autoSaving,
    },
    dispatch,
  } = useGlobal();

  const { editor } = useContext(EditorContext);

  const [pageTitle, setPageTitle] = useState('');
  const [pageSubTitle, setPageSubTitle] = useState('');
  const [hasSubTitle, setHasSubTitle] = useState(false);

  const activePageRef = useRef(activePage);
  activePageRef.current = activePage;

  const pageTitleRef = useRef(pageTitle);
  pageTitleRef.current = pageTitle;

  const subTitleRef = useRef(pageSubTitle);
  subTitleRef.current = pageSubTitle;

  usePagePreview(docId);

  // TODO: register page popup events on doc change and refactor the popup redister logic
  useEffect(() => {
    loadInitialPageData(docId, history);

    setTimeout(() => {
      const contentBox = document.querySelector('.content'); 
      contentBox?.scrollTo({ top: contentBox.scrollHeight, behavior: 'smooth'  });
      editor?.commands.focus();
    }, 1500);
  }, [docId]);

  useEffect(() => {
    editor?.on('update', editorUpdate);

    setTimeout(() => {
      const contentBox = document.querySelector('.content'); 
      contentBox?.scrollTo({ top: contentBox.scrollHeight, behavior: 'smooth'  });
      editor?.commands.focus();
    }, 1500);

    return () => {
      editor?.off('update', editorUpdate);
    };
  }, [editor]);

  useEffect(() => {
    if (activePage) {
      const folder = allFolders.find(f => f.id === activePage.parentFolder);
      setActiveFolder(folder);
    }
  }, [activePage, allFolders]);

  useEffect(() => {
    if (activePage) {
      setPageTitle(
        activePage.title === 'Untitled page' ? '' : activePage.title
      );
      setPageSubTitle(activePage.subTitle || '');
      setHasSubTitle(!!activePage.subTitle);

      activePage.content
        ? editor?.commands.setContent(activePage.content)
        : editor?.commands.clearContent();

      editor?.commands.focus();
      setTimeout(() => {
        editor?.commands.blur();
      });
    }
  }, [activePage]);

  let timeout = null;

  function editorUpdate({ editor }) {
    dispatch({ type: SET_AUTO_SAVING, payload: true });
    window.addEventListener('beforeunload', confirmLeave, false);

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      savePageContent({
        title: pageTitleRef.current,
        subTitle: subTitleRef.current,
        content: editor.getHTML(),
        pageId: activePageRef.current.id,
      });

      window.removeEventListener('beforeunload', confirmLeave, false);
      dispatch({ type: SET_AUTO_SAVING, payload: false });
    }, 3000);
  }

  function confirmLeave(e) {
    e.preventDefault();
    // for chrome, firefox
    e.returnValue = 'Changes you have made are not saved.';
    // for safari
    return 'Changes you have made are not saved.';
  }

  function addSubtitle() {
    setHasSubTitle(true);
  }

  return (
    <>
      <Prompt
        when={autoSaving}
        message='Changes you have made are not saved. Are you sure to navigate?'
      />
      <IconsSvgScripts />
      <div className='page page-article'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='page-col page-col01'>
              <MainMenu />
            </div>
            <div className='page-col'>
              <MainSidebar />
            </div>
            <div className='page-col page-col02'>
              <PageControls addSubtitle={addSubtitle} />
              <div className='content'>
                <div className='content__wrap'>
                  <input
                    type='text'
                    name='title'
                    className='title-lg w-full inline-block bg-transparent focus:outline-none'
                    placeholder='Title'
                    value={pageTitle}
                    onChange={e => {
                      setPageTitle(e.target.value);
                      editorUpdate({ editor });
                    }}
                  />
                  {hasSubTitle && (
                    <input
                      type='text'
                      className='subtitle bg-transparent w-full'
                      placeholder='Enter subheading'
                      value={pageSubTitle}
                      onChange={e => {
                        setPageSubTitle(e.target.value);
                        editorUpdate({ editor });
                      }}
                    />
                  )}
                  <div className='custom-border'></div>

                  <Editor />
                </div>
              </div>
            </div>
            {activePage?.hasInfoBox && (
              <div className='page-col page-col03'>
                <Infobox />
              </div>
            )}
          </div>
        </div>
        {pagePopover && <PagePreview />}
      </div>
      {openLinkModal && <LinkModal />}
      {openShareModal && <ShareModal />}
      {searchModal && <SearchModal />}
    </>
  );
}

export default Article;
