import { APP_URL } from 'app.config';
import {
  SET_PAGE_POPUP,
  SET_PAGE_PREVIEW_TIMEOUT,
  SET_POPUP_INFO,
} from 'context/types';

export function openPagePreview(e, dispatch) {
  const appUrl = APP_URL;
  const url = new URL(e.target.href);

  if (appUrl.includes(url.hostname) && url.pathname.length > 1) {
    dispatch({
      type: SET_POPUP_INFO,
      payload: {
        pageId: url.pathname.split('/').reverse()[0],
        popupPos: e.target.getBoundingClientRect(),
        pupupLink: url.href,
      },
    });
    dispatch({ type: SET_PAGE_POPUP, payload: true });
  }
}

export function closePagePreview(dispatch) {
  const previewTimeout = setTimeout(() => {
    dispatch({ type: SET_PAGE_POPUP, payload: false });
  }, 200);

  dispatch({ type: SET_PAGE_PREVIEW_TIMEOUT, payload: previewTimeout });
}
