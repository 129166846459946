import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as SortIcon } from 'assets/svg/sort.svg';

function DataItem({ itemId, title, value, delItem, updateItem }) {
  const [editTitle, setEditTitle] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [itemTitle, setItemTitle] = useState('');
  const [itemValue, setItemValue] = useState('');
  const titleInput = useRef();
  const valueInput = useRef();

  useEffect(() => {
    titleInput.current && titleInput.current.focus();
    titleInput.current &&
      titleInput.current.setSelectionRange(
        titleInput.current.value.length,
        titleInput.current.value.length
      );
  }, [editTitle]);

  useEffect(() => {
    valueInput.current && valueInput.current.focus();
    valueInput.current &&
      valueInput.current.setSelectionRange(
        valueInput.current.value.length,
        valueInput.current.value.length
      );
  }, [editValue]);

  return (
    <>
      <span
        className='text-white ml-[3px] pt-1 invisible group-hover:visible'
        style={{ height: 'max-content' }}
      >
        <SortIcon className='w-[14px] fill-current' />
      </span>

      {!editTitle ? (
        <a
          className='data__title whitespace-pre-wrap font-bold cursor-text hover:opacity-100'
          onClick={e => {
            setEditTitle(true);
            setItemTitle(e.target.textContent);
          }}
        >
          {title}
        </a>
      ) : (
        <ClickAwayListener
          onClickAway={() => {
            setEditTitle(false);
            setItemTitle('');
          }}
        >
          <form
            className='data__title h-full'
            onSubmit={e => {
              e.preventDefault();
              updateItem(itemId, { title: itemTitle });
              setEditTitle(false);
            }}
          >
            <TextareaAutosize
              className='w-full h-full bg-transparent mt-0 resize-none overflow-hidden font-bold'
              value={itemTitle}
              ref={ref => (titleInput.current = ref)}
              placeholder='Item Title'
              onChange={e => {
                setItemTitle(e.target.value);
                if (e.target.value.trim()) {
                  updateItem(itemId, { title: e.target.value });
                }
              }}
              onKeyDown={e => {
                if (e.key === 'Escape') {
                  setEditTitle(false);
                  setItemTitle('');
                }
              }}
            />
          </form>
        </ClickAwayListener>
      )}

      {!editValue ? (
        <a
          className='data__val whitespace-pre-wrap font-normal cursor-text hover:opacity-100'
          onClick={e => {
            setEditValue(true);
            setItemValue(e.target.textContent);
          }}
        >
          {value}
        </a>
      ) : (
        <ClickAwayListener
          onClickAway={() => {
            setEditValue(false);
            setItemValue('');
          }}
        >
          <form
            className='data__val h-full'
            onSubmit={e => {
              e.preventDefault();
              updateItem(itemId, { value: itemValue });
              setEditValue(false);
            }}
          >
            <TextareaAutosize
              className='w-full h-full bg-transparent mt-0 resize-none overflow-hidden'
              value={itemValue}
              ref={ref => (valueInput.current = ref)}
              placeholder='Item Value'
              onChange={e => {
                setItemValue(e.target.value);
                if (e.target.value.trim()) {
                  updateItem(itemId, { value: e.target.value });
                }
              }}
              onKeyDown={e => {
                if (e.key === 'Escape') {
                  setEditValue(false);
                  setItemValue('');
                }
              }}
            />
          </form>
        </ClickAwayListener>
      )}

      <a
        className='data_icon invisible h-full cursor-pointer group-hover:visible p-1 rounded-full hover:bg-dark'
        onClick={() => delItem(itemId)}
      >
        <svg className='icon icon-plus02 w-3 h-3 transform rotate-45 fill-current'>
          <use xlinkHref='#icon-plus02'></use>
        </svg>
      </a>
    </>
  );
}

DataItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  delItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

DataItem.defaultProps = {
  title: 'Title',
  value: 'Description goes here',
};

export default DataItem;
