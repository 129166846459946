import PropTypes from 'prop-types';
import classNames from 'classnames';

function PageMenu({ children, show }) {
  return (
    <ul className={classNames(['nav-submenu', show && 'in'])}>{children}</ul>
  );
}

PageMenu.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PageMenu.defaultProps = {
  show: false,
};

export default PageMenu;
