import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from 'react-click-away-listener';
import DropdownBox from 'components/layouts/DropdownBox';
import DropdownItem from 'components/layouts/DropdownBox/DropdownItem';
import DropdownTitle from 'components/layouts/DropdownBox/DropdownTitle';
import Saperator from 'components/layouts/DropdownBox/Saperator';
import { useGlobal } from 'context/GlobalContext';
import { usePages } from 'context/PagesContext';
import { TOGGLE_SEARCH_MODAL, TOGGLE_SHARE_MODAL } from 'context/types';
import { useParams } from 'react-router-dom';
import EditorContext from 'context/EditorContext';
import { nanoid } from 'utils';
import { storageRef } from 'firebase/init';

function PageControls({ addSubtitle }) {
  const { docId } = useParams();

  const [showAddItem, setShowAddItem] = useState(false);
  const [dotsMenu, setDotsMenu] = useState(false);

  const { addInfoBox, sharePage } = usePages();
  const { dispatch } = useGlobal();

  const { editor, toggleTableOfContents } = useContext(EditorContext);

  async function changeFile(e) {
    if (!e.target.files.length) return;

    const allowedTypes = ['jpeg', 'jpg', 'png', 'bmp', 'gif'];

    const file = e.target.files[0];
    const type = file.type.split('/').pop().toLowerCase();

    if (!allowedTypes.includes(type)) {
      alert('Please select a valid image file.');
      return;
    }

    const url = await uploadFile(file);
    e.target.value = '';

    insertImage(url);
    setShowAddItem(false);
  }

  async function uploadFile(file) {
    const ext = file.name.split('.').reverse()[0];
    const name = nanoid();

    const uploadFileRef = storageRef.child(`uploads/${name}.${ext}`);
    await uploadFileRef.put(file);
    const url = await uploadFileRef.getDownloadURL();

    return url;
  }

  function insertImage(url) {
    editor.chain().focus().setImage({ src: url }).run();
  }

  return (
    <div className='control'>
      <div className='control-btns'>
        <ClickAwayListener onClickAway={() => setShowAddItem(false)}>
          <div className='dropdown-info'>
            <button
              className='btn-dropdown btn btn-icon'
              onClick={() => setShowAddItem(!showAddItem)}
            >
              <svg className='icon icon-plus02'>
                <use xlinkHref='#icon-plus02'></use>
              </svg>
            </button>
            <DropdownBox show={showAddItem}>
              <DropdownTitle>Add...</DropdownTitle>
              <DropdownItem
                href='#'
                onClick={e => {
                  e.preventDefault();
                  addSubtitle();
                  setShowAddItem(false);
                }}
              >
                <svg className='icon icon-caps'>
                  <use xlinkHref='#icon-caps'></use>
                </svg>
                Sub Heading
              </DropdownItem>
              <DropdownItem
                href='#'
                onClick={e => {
                  e.preventDefault();
                  addInfoBox();
                  setShowAddItem(false);
                }}
              >
                <svg className='icon icon-grid02'>
                  <use xlinkHref='#icon-grid02'></use>
                </svg>{' '}
                Info box
              </DropdownItem>
              <Saperator />
              <DropdownItem href='#' className='p-0'>
                <label className='cursor-pointer w-full inline-flex items-center font-medium text-[#d1d2d3] leading-[17px] text-[14px] m-0 py-1.5 px-[17px]'>
                  <svg className='icon icon-img'>
                    <use xlinkHref='#icon-img'></use>
                  </svg>{' '}
                  <input
                    type='file'
                    name='file'
                    className='hidden'
                    onChange={changeFile}
                  />
                  Image
                </label>
              </DropdownItem>
            </DropdownBox>
          </div>
        </ClickAwayListener>
        <span className='separator'>|</span>
        <ClickAwayListener onClickAway={() => setDotsMenu(false)}>
          <div className='relative flex items-center'>
            <button className='btn btn-icon' onClick={() => setDotsMenu(true)}>
              <svg className='icon icon-dots'>
                <use xlinkHref='#icon-dots'></use>
              </svg>
            </button>
            <DropdownBox show={dotsMenu}>
              <DropdownTitle>More...</DropdownTitle>
              <DropdownItem
                href='#'
                onClick={e => {
                  e.preventDefault();
                  toggleTableOfContents();
                  setShowAddItem(false);
                }}
              >
                <svg className='icon icon-caps'>
                  <use xlinkHref='#icon-caps'></use>
                </svg>
                Toggle Table of contents
              </DropdownItem>
              <DropdownItem
                className='group'
                onClick={e => {
                  e.preventDefault();
                  sharePage(docId);
                  dispatch({ type: TOGGLE_SHARE_MODAL });
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  className='icon icon-caps !fill-[#a3a3a6] !stroke-[transparent] group-hover:!stroke-[transparent] group-hover:!fill-[#fff]'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M13.12 17.023l-4.199-2.29a4 4 0 1 1 0-5.465l4.2-2.29a4 4 0 1 1 .959 1.755l-4.2 2.29a4.008 4.008 0 0 1 0 1.954l4.199 2.29a4 4 0 1 1-.959 1.755zM6 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm11-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' />
                </svg>
                Share this page
              </DropdownItem>
            </DropdownBox>
          </div>
        </ClickAwayListener>
        <button
          className='btn btn-icon btn-search'
          onClick={() => dispatch({ type: TOGGLE_SEARCH_MODAL })}
        >
          <svg className='icon icon-search'>
            <use xlinkHref='#icon-search'></use>
          </svg>
        </button>
      </div>
    </div>
  );
}

PageControls.propTypes = {
  addSubtitle: PropTypes.func.isRequired,
};

export default PageControls;
