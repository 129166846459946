import PropTypes from 'prop-types';

function DropdownTitle({ children, className }) {
  return (
    <div className={`dropdown-box__title-sm ${className}`}>{children}</div>
  );
}

DropdownTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

DropdownTitle.defaultProps = {
  className: '',
};

export default DropdownTitle;
