import { useAuth } from 'context/AuthContext';
import { useGlobal } from 'context/GlobalContext';
import { TOGGLE_SEARCH_MODAL, TOGGLE_SIDEBAR } from 'context/types';
import { useHistory } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import MenuItem from './MenuItem';

function MainMenu() {
  const { logout } = useAuth();
  const history = useHistory();

  const { dispatch } = useGlobal();

  return (
    <div className='main-menu'>
      <MenuHeader />
      <div className='nav-category'>
        <div className='nav-category__content'>
          <ul className='nav-category__list'>
            <MenuItem
              href='#'
              icon={
                <svg className='icon icon-search'>
                  <use xlinkHref='#icon-search'></use>
                </svg>
              }
              onClick={e => {
                e.preventDefault();
                dispatch({ type: TOGGLE_SEARCH_MODAL });
              }}
            >
              Search
            </MenuItem>
            <MenuItem
              href='#'
              icon={
                <svg className='icon icon-cat02'>
                  <use xlinkHref='#icon-cat02'></use>
                </svg>
              }
              active={true}
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: TOGGLE_SIDEBAR });
              }}
            >
              Pages
            </MenuItem>
            <MenuItem
              href='#'
              icon={
                <svg className='icon icon-cat04'>
                  <use xlinkHref='#icon-cat04'></use>
                </svg>
              }
            >
              Favorites
            </MenuItem>
            <MenuItem
              href='#'
              icon={
                <svg className='icon icon-cat08'>
                  <use xlinkHref='#icon-cat08'></use>
                </svg>
              }
            >
              Home
            </MenuItem>
            <MenuItem
              href='#'
              icon={
                <svg className='icon icon-cat11'>
                  <use xlinkHref='#icon-cat11'></use>
                </svg>
              }
            >
              Ai-Writer
            </MenuItem>
          </ul>
          <ul className='nav-category__list nav-category__list_02'>
            <MenuItem
              href='#'
              icon={
                <svg className='icon icon-cat06'>
                  <use xlinkHref='#icon-cat06'></use>
                </svg>
              }
              onClick={async e => {
                e.preventDefault();
                await logout();
                history.push('/login');
              }}
            >
              Logout
            </MenuItem>
            <MenuItem
              href='#'
              icon={
                <svg className='icon icon-cat07'>
                  <use xlinkHref='#icon-cat07'></use>
                </svg>
              }
            >
              Help
            </MenuItem>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MainMenu;
