import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import ContextProviders from 'context/ContextProviders';
import { BrowserRouter as Router } from 'react-router-dom';

import 'styles/scss/main.scss';
import 'styles/index.css';

// detect darkmode
if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  document.body.classList.add('dark');
}

ReactDOM.render(
  <React.StrictMode>
    <ContextProviders>
      <Router>
        <App />
      </Router>
    </ContextProviders>
  </React.StrictMode>,
  document.getElementById('root')
);
