import { useGlobal } from 'context/GlobalContext';
import { TOGGLE_SHARE_MODAL } from 'context/types';
import { useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useParams } from 'react-router-dom';
import { APP_URL } from 'app.config';

function ShareModal() {
  const { docId } = useParams();

  const { dispatch } = useGlobal();

  const [copied, setCopied] = useState(false);
  const shareInput = useRef();
  const isMounted = useRef(false);

  const appUrl = APP_URL;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const copyToClipboard = () => {
    shareInput.current.select();
    document.execCommand('copy');
    shareInput.current.focus();
    setCopied(true);

    setTimeout(() => {
      if (isMounted.current) {
        setCopied(false);
      }
    }, 2000);
  };

  return (
    <div className='bg-black bg-opacity-75 z-[9999] fixed inset-0 w-full h-full flex justify-center'>
      <ClickAwayListener
        onClickAway={() => dispatch({ type: TOGGLE_SHARE_MODAL })}
      >
        <div
          className='search ml-0 max-w-lg mt-40'
          style={{ height: 'max-content' }}
        >
          <div className='search-form__box !block text-white mt-4 h-auto px-6 py-8 pt-7'>
            <h3 className='text-2xl'>Share Link!</h3>
            <div className='flex mt-3'>
              <input
                type='text'
                className='w-full py-1.5 px-2 search-form__box rounded-r-none'
                ref={shareInput}
                value={`${appUrl}/page/${docId}`}
                readOnly
                onClick={copyToClipboard}
              />
              <button
                onClick={copyToClipboard}
                className='bg-[#2E2F35] rounded-r-md px-3'
              >
                {copied ? 'Copied!' : 'Copy'}
              </button>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default ShareModal;
