/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import DropdownBox from 'components/layouts/DropdownBox';
import DropdownItem from 'components/layouts/DropdownBox/DropdownItem';
import DropdownTitle from 'components/layouts/DropdownBox/DropdownTitle';
import { usePages } from 'context/PagesContext';
import { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { APP_URL } from 'app.config';
import { useGlobal } from 'context/GlobalContext';
import { closePagePreview, openPagePreview } from 'utils/page-preview';

// TODO: do the keyboard accessibility later
let onKeyDown;

const actions = {
  link: 'link',
  create: 'create',
};

function SuggestionList(props) {
  console.log(props);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [items, setItems] = useState([]);

  const { dispatch } = useGlobal();

  const {
    pages: { allPages },
    setPageState,
    createNewPage,
  } = usePages();

  useEffect(() => {
    console.log(allPages);
    setItems(
      allPages
        .filter(p =>
          p.title.toLowerCase().startsWith(props.query.toLowerCase())
        )
        .map(p => ({ id: p.id, title: p.title }))
        .slice(0, 5)
    );
  }, [props.query]);

  useEffect(() => {
    setSelectedIndex(null);
  }, [items]);

  onKeyDown = function onKeyDown(event) {
    if (event.key === 'ArrowUp') {
      upHandler();
      return true;
    }

    if (event.key === 'ArrowDown') {
      downHandler();
      return true;
    }

    if (event.key === 'Enter') {
      enterHandler();
      return true;
    }

    return false;
  };

  function upHandler() {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  }

  function downHandler() {
    setSelectedIndex((selectedIndex + 1) % items.length);
  }

  function enterHandler() {
    selectItem(selectedIndex);
  }

  function insertLink(page) {
    const url = `${APP_URL}/p/${page.id}`;

    props.editor
      .chain()
      .focus()
      .insertContentAt(props.range, `<a href="${url}">${page.title}</a>`)
      .run();

    const allLinks = document.querySelectorAll('.ProseMirror a');

    [...allLinks].forEach(linkItem => {
      if (linkItem.href === url) {
        linkItem.addEventListener('mouseover', e =>
          openPagePreview(e, dispatch)
        );
        linkItem.addEventListener('mouseout', () => closePagePreview(dispatch));
      }
    });
  }

  async function selectItem(item, action) {
    switch (action) {
      case actions.link:
        insertLink(item);
        break;

      case actions.create: {
        const pageTitle = item;
        const addedPage = await createNewPage(null, pageTitle);

        setPageState({
          allPages: [...allPages, addedPage],
        });

        insertLink(addedPage);
        break;
      }

      default:
        break;
    }
  }

  return (
    <DropdownBox
      show={true}
      tabIndex='0'
      onKeyDown={onKeyDown}
      style={{ position: 'relative', top: 'inherit' }}
    >
      {!!items.length && (
        <>
          <DropdownTitle className='mt-2'>Link a page</DropdownTitle>

          {items.map((item, index) => (
            <DropdownItem
              className={classNames([
                'focus:outline-black',
                index === selectedIndex && 'active',
              ])}
              key={index}
              onClick={e => {
                e.preventDefault();
                selectItem(item, actions.link);
              }}
            >
              {item.title}
            </DropdownItem>
          ))}
        </>
      )}

      {!!props.query.length && (
        <>
          <DropdownTitle className='mt-2'>Create a page</DropdownTitle>

          <DropdownItem
            className='focus:outline-white'
            onClick={e => {
              e.preventDefault();
              selectItem(props.query, actions.create);
            }}
          >
            {props.query}
          </DropdownItem>
        </>
      )}
    </DropdownBox>
  );
}

SuggestionList.onKeyDown = onKeyDown;

export default SuggestionList;

export class MentionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
    };
  }

  componentDidUpdate(oldProps) {
    if (this.props.items !== oldProps.items) {
      this.setState({
        selectedIndex: 0,
      });
    }
  }

  onKeyDown({ event }) {
    if (event.key === 'ArrowUp') {
      this.upHandler();
      return true;
    }

    if (event.key === 'ArrowDown') {
      this.downHandler();
      return true;
    }

    if (event.key === 'Enter') {
      this.enterHandler();
      return true;
    }

    return false;
  }

  upHandler() {
    this.setState({
      selectedIndex:
        (this.state.selectedIndex + this.props.items.length - 1) %
        this.props.items.length,
    });
  }

  downHandler() {
    this.setState({
      selectedIndex: (this.state.selectedIndex + 1) % this.props.items.length,
    });
  }

  enterHandler() {
    this.selectItem(this.state.selectedIndex);
  }

  selectItem(index) {
    const item = this.props.items[index];
    if (item) {
      this.props.command({ id: item });
    }
  }

  render() {
    const items = [
      'Lea Thompson',
      'Cyndi Lauper',
      'Tom Cruise',
      'Madonna',
      'Jerry Hall',
      'Joan Collins',
      'Winona Ryder',
      'Christina Applegate',
      'Alyssa Milano',
      'Molly Ringwald',
      'Ally Sheedy',
      'Debbie Harry',
      'Olivia Newton-John',
      'Elton John',
      'Michael J. Fox',
      'Axl Rose',
      'Emilio Estevez',
      'Ralph Macchio',
      'Rob Lowe',
      'Jennifer Grey',
      'Mickey Rourke',
      'John Cusack',
      'Matthew Broderick',
      'Justine Bateman',
      'Lisa Bonet',
    ]
      .filter(item =>
        item.toLowerCase().startsWith(this.props.query.toLowerCase())
      )
      .slice(0, 5);

    return (
      <div className='items'>
        {items.map((item, index) => (
          <button
            className={`item ${
              index === this.state.selectedIndex ? 'is-selected' : ''
            }`}
            key={index}
            onClick={() => this.selectItem(index)}
          >
            {item}
          </button>
        ))}
      </div>
    );
  }
}

MentionList.propTypes = {
  items: PropTypes.array,
  command: PropTypes.func,
};
