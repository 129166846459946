import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import DropdownBox from 'components/layouts/DropdownBox';
import DropdownItem from 'components/layouts/DropdownBox/DropdownItem';
import DropdownTitle from 'components/layouts/DropdownBox/DropdownTitle';

const FolderItemDropDown = forwardRef(
  ({ folder, folderOption, setFolderOptions, addPage, delFolder }, ref) => {
    const history = useHistory();

    return (
      <div
        ref={ref}
        className='dropdown-info'
        onClick={(e) => {
          e.stopPropagation();
          setFolderOptions({
            folderMenu:
              folder.id !== folderOption.folderMenu ? folder.id : null,
          });
        }}
      >
        <button className='btn-dropdown btn btn-icon'>
          <svg className='icon icon-dots'>
            <use xlinkHref='#icon-dots'></use>
          </svg>
        </button>
        <DropdownBox
          show={folder.id === folderOption.folderMenu}
          style={{
            top: '22px',
            right: '14px',
            maxWidth: '220px',
          }}
        >
          <DropdownTitle className='mt-3'>More...</DropdownTitle>
          <DropdownItem
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setFolderOptions({
                folderMenu: null,
                editFolderId: folder.id,
              });
            }}
          >
            <svg className='icon icon-edit'>
              <use xlinkHref='#icon-edit'></use>
            </svg>{' '}
            Rename Folder
          </DropdownItem>
          <DropdownItem
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setFolderOptions({ folderMenu: null });
              delFolder(folder.id);
            }}
          >
            <svg className='icon icon-del03'>
              <use xlinkHref='#icon-del03'></use>
            </svg>{' '}
            Delete Folder
          </DropdownItem>
          <DropdownItem
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setFolderOptions({ folderMenu: null });
              addPage(folder.id, history);
            }}
          >
            <svg className='icon icon-plus'>
              <use xlinkHref='#icon-plus'></use>
            </svg>{' '}
            New Page
          </DropdownItem>
        </DropdownBox>
      </div>
    );
  }
);

FolderItemDropDown.displayName = 'FolderItemDropDown';

FolderItemDropDown.propTypes = {
  folder: PropTypes.object.isRequired,
  folderOption: PropTypes.object.isRequired,
  setFolderOptions: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  delFolder: PropTypes.func.isRequired,
};

export default FolderItemDropDown;
