import { EditorContent } from '@tiptap/react';
import { useContext } from 'react';
import BubbleMenu from './BubbleMenu';
import EditorContext from 'context/EditorContext';

function Editor() {
  const { editor } = useContext(EditorContext);

  return (
    <div className='editor-section mt-4 mb-8'>
      {editor && <BubbleMenu editor={editor} />}

      <EditorContent editor={editor} />
    </div>
  );
}

export default Editor;
