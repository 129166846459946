export const APP_URL = process.env.REACT_APP_WEBSITE_URL;

export const MAGIC_PK_KEY = process.env.REACT_APP_MAGIC_PK_KEY;

export const FIREBASE_CONFIG = {
  API_KEY: process.env.REACT_APP_FB_API_KEY,
  AUTH_DOMAIN: process.env.REACT_APP_FB_AUTH_DOMAIN,
  PROJECT_ID: process.env.REACT_APP_FB_PROJECT_ID,
  STORAGE_BUCKET: process.env.REACT_APP_FB_STORAGE_BUCKET,
  MESSAGING_SENDER_ID: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  APP_ID: process.env.REACT_APP_FB_APP_ID,
};
