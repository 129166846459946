import { useContext, useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import EditorContext from 'context/EditorContext';
import { useGlobal } from 'context/GlobalContext';
import { usePages } from 'context/PagesContext';
import { TOGGLE_LINK_MODAL } from 'context/types';
import { APP_URL } from 'app.config';
import { closePagePreview, openPagePreview } from 'utils/page-preview';

function LinkModal() {
  const { dispatch } = useGlobal();

  const {
    pages: { allPages },
  } = usePages();

  const linkInput = useRef();

  const { editor } = useContext(EditorContext);

  const [link, setLink] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    linkInput.current.focus();
  }, []);

  const searchPages = value => {
    if (!value.trim()) {
      setResults([]);
      return;
    }

    setResults(
      allPages.filter(page => {
        if (page.title.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
          return true;
        } else {
          return false;
        }
      })
    );
  };

  const addLink = (e, url) => {
    e.preventDefault();
    editor.chain().focus().setLink({ href: url }).run();

    const allLinks = document.querySelectorAll('.ProseMirror a');

    [...allLinks].forEach(linkItem => {
      if (linkItem.href === url) {
        linkItem.addEventListener('mouseover', e =>
          openPagePreview(e, dispatch)
        );
        linkItem.addEventListener('mouseout', () => closePagePreview(dispatch));
      }
    });

    dispatch({ type: TOGGLE_LINK_MODAL });
  };

  return (
    <div className='bg-black bg-opacity-75 z-[9999] fixed inset-0 w-full h-full flex justify-center'>
      <ClickAwayListener
        onClickAway={() => dispatch({ type: TOGGLE_LINK_MODAL })}
      >
        <div
          className='search ml-0 max-w-2xl mt-40'
          style={{ height: 'max-content' }}
        >
          <form className='search-form' onSubmit={e => addLink(e, link)}>
            <div className='search-form__box h-12'>
              <svg className='icon icon-search top-1/2 transform translate-y-1/2 -mt-0.5'>
                <use xlinkHref='#icon-search'></use>
              </svg>
              <input
                type='text'
                placeholder='Search of internal page or paste external link'
                className='search-form__input'
                ref={linkInput}
                value={link}
                onChange={e => {
                  setLink(e.target.value);
                  searchPages(e.target.value);
                }}
                onKeyDown={e =>
                  e.key === 'Escape' && dispatch({ type: TOGGLE_LINK_MODAL })
                }
              />
            </div>
          </form>
          {!!results.length && (
            <ul className='search-form__box !block text-white mt-4 h-auto max-h-80 overflow-x-hidden overflow-y-auto'>
              {results.map(page => (
                <li
                  key={page.id}
                  className='px-3 py-3.5 w-full cursor-pointer hover:bg-[#161618]'
                  onClick={e => {
                    setLink(`${APP_URL}/p/${page.id}`);
                    setResults([]);
                    addLink(e, `${APP_URL}/p/${page.id}`);
                  }}
                >
                  {page.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default LinkModal;
