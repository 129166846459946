import PropTypes from 'prop-types';
import classNames from 'classnames';
import { forwardRef } from 'react';

const DropdownBox = forwardRef(({ show, children, ...rest }, ref) => {
  return (
    <div
      className={classNames(['dropdown-box', show && 'dropdown-box_in'])}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  );
});

DropdownBox.displayName = 'DropdownBox';

DropdownBox.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

DropdownBox.defaultProps = {
  show: false,
};

export default DropdownBox;
