import PropTypes from 'prop-types';
import { AuthProvider } from './AuthContext';
import { EditorProvider } from './EditorContext';
import { FoldersProvider } from './FoldersContext';
import { GlobalProvider } from './GlobalContext';
import { PagesProvider } from './PagesContext';

function ContextProviders({ children }) {
  return (
    <AuthProvider>
      <EditorProvider>
        <FoldersProvider>
          <PagesProvider>
            <GlobalProvider>{children}</GlobalProvider>
          </PagesProvider>
        </FoldersProvider>
      </EditorProvider>
    </AuthProvider>
  );
}

ContextProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProviders;
