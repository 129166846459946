import PropTypes from 'prop-types';
import DropdownBox from 'components/layouts/DropdownBox';
import DropdownItem from 'components/layouts/DropdownBox/DropdownItem';
import { forwardRef } from 'react';
import { usePages } from 'context/PagesContext';
import DropdownTitle from 'components/layouts/DropdownBox/DropdownTitle';

const PageItemDropDown = forwardRef(
  ({ page, deletePage, folderOption, setFolderOptions }, ref) => {
    const { duplicatePage } = usePages();

    return (
      <div
        ref={ref}
        className='dropdown-info'
        onClick={e => {
          e.stopPropagation();
          setFolderOptions({
            pageMenu: page.id !== folderOption.pageMenu ? page.id : null,
          });
        }}
      >
        <button className='btn-dropdown btn btn-icon'>
          <svg className='icon icon-dots'>
            <use xlinkHref='#icon-dots'></use>
          </svg>
        </button>
        <DropdownBox
          show={page.id === folderOption.pageMenu}
          style={{
            top: '22px',
            left: 'initial',
            right: '10px',
            maxWidth: '220px',
          }}
        >
          <DropdownTitle className='mt-3'>More...</DropdownTitle>
          <DropdownItem
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              duplicatePage(page.id);
              setFolderOptions({ pageMenu: null });
            }}
          >
            <svg className='icon icon-document'>
              <use xlinkHref='#icon-document'></use>
            </svg>{' '}
            Duplicate Page
          </DropdownItem>
          <DropdownItem
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              deletePage(page.id);
              setFolderOptions({ pageMenu: null });
            }}
          >
            <svg className='icon icon-del03'>
              <use xlinkHref='#icon-del03'></use>
            </svg>{' '}
            Delete Page
          </DropdownItem>
        </DropdownBox>
      </div>
    );
  }
);

PageItemDropDown.displayName = 'PageItemDropDown';

PageItemDropDown.propTypes = {
  page: PropTypes.object.isRequired,
  folderOption: PropTypes.object.isRequired,
  deletePage: PropTypes.func.isRequired,
  setFolderOptions: PropTypes.func.isRequired,
};

export default PageItemDropDown;
