import Loading from 'components/layouts/Loading';
import { useAuth } from 'context/AuthContext';
import { usePages } from 'context/PagesContext';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

function Login() {
  const {
    auth: { isLoggedIn, loadingUser },
    login,
    socialLogin,
  } = useAuth();

  const [email, setEmail] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);

  const history = useHistory();

  const {
    pages: { activePage },
    getFirstPage,
  } = usePages();

  useEffect(() => {
    if (activePage) {
      history.push(`/p/${activePage.id}`);
    }

    if (isLoggedIn) {
      getFirstPage();
    }

    document.body.style.background = '#000';
    return () => {
      document.body.style.removeProperty('background');
    };
    // eslint-disable-next-line
  }, [activePage, isLoggedIn]);

  if ((isLoggedIn && !activePage) || loadingUser) {
    return <Loading />;
  }

  const handleSubmit = async e => {
    setLoggingIn(true);
    e.preventDefault();
    await login(email);
    setLoggingIn(false);
  };

  return (
    <div className='overflow'>
      <div className='section-herod-copy-login wf-section'>
        <div className='content-copylogin'>
          <div
            data-w-id='ed7d5dc5-6a55-bf51-056e-01a16d322f0a'
            className='blurred-hero-copylogin'
          >
            <div className='blurred-copylogin'>
              <img
                className='blurred-red---hero'
                src='/assets/images/blurred_1.png'
                alt=''
                style={{
                  opacity: 1,
                  marginTop: '-35%',
                  marginLeft: '-45%',
                  WebkitTransform:
                    'translate3d(0.0048%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  MozTransform:
                    'translate3d(0.0048%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  msTransform:
                    'translate3d(0.0048%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transform:
                    'translate3d(0.0048%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                }}
                // style='
                //   opacity: 0;
                //   -webkit-transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1)
                //     rotateX(0) rotateY(0) rotateZ(80deg) skew(0, 0);
                //   -moz-transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1)
                //     rotateX(0) rotateY(0) rotateZ(80deg) skew(0, 0);
                //   -ms-transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1)
                //     rotateX(0) rotateY(0) rotateZ(80deg) skew(0, 0);
                //   transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1)
                //     rotateX(0) rotateY(0) rotateZ(80deg) skew(0, 0);
                // '
                sizes='(max-width: 991px) 100vw, 660px'
                data-w-id='753c3e46-984e-9517-9bc0-0809b74d3b79'
                loading='lazy'
                srcSet='
                  /assets/images/blurred_1-p-500.png 500w,
                  /assets/images/blurred_1.png 763w
                '
              />
              <img
                className='blurred-blue---hero'
                src='/assets/images/blurred_2.png'
                alt=''
                style={{
                  opacity: 1,
                  marginTop: '-36%',
                  marginLeft: '5%',
                  WebkitTransform:
                    'translate3d(-0.00252%, 0.0006%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  MozTransform:
                    'translate3d(-0.00252%, 0.0006%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  msTransform:
                    'translate3d(-0.00252%, 0.0006%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transform:
                    'translate3d(-0.00252%, 0.0006%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                }}
                // style='
                //   opacity: 0;
                //   -webkit-transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1)
                //     rotateX(0) rotateY(0) rotateZ(80deg) skew(0, 0);
                //   -moz-transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1)
                //     rotateX(0) rotateY(0) rotateZ(80deg) skew(0, 0);
                //   -ms-transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1)
                //     rotateX(0) rotateY(0) rotateZ(80deg) skew(0, 0);
                //   transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1)
                //     rotateX(0) rotateY(0) rotateZ(80deg) skew(0, 0);
                // '
                sizes='(max-width: 991px) 100vw, 660px'
                data-w-id='c9461a07-2231-25f8-d5e0-1cde86f174c0'
                loading='lazy'
                srcSet='
                  /assets/images/blurred_2-p-500.png 500w,
                  /assets/images/blurred_2.png       863w
                '
              />
            </div>
            <div className='block-hero-img'>
              <div
                data-w-id='cba6cee5-487b-935c-5668-db5f2f0c6531'
                className='get-started-copy'
              >
                <div
                  data-w-id='cba6cee5-487b-935c-5668-db5f2f0c6532'
                  style={{
                    WebkitTransform:
                      'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                    MozTransform:
                      'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                    msTransform:
                      'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                    transform:
                      'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  }}
                  // style='
                  //   -webkit-transform: translate3d(0, 20px, 0) scale3d(1, 1, 1)
                  //     rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  //   -moz-transform: translate3d(0, 20px, 0) scale3d(1, 1, 1)
                  //     rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  //   -ms-transform: translate3d(0, 20px, 0) scale3d(1, 1, 1)
                  //     rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  //   transform: translate3d(0, 20px, 0) scale3d(1, 1, 1)
                  //     rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  //   opacity: 0;
                  // '
                  className='te-copy'
                >
                  <h4 className='heading-form-copy'>Get Started</h4>
                  <p className='paragraph-form-copy'>
                    We’ll send you a sign-in code
                    <br />
                  </p>
                  <div className='form-block-started w-form'>
                    <form
                      id='wf-form-Get-Started'
                      name='wf-form-Get-Started'
                      data-name='Get Started'
                      className='form-started'
                      onSubmit={handleSubmit}
                    >
                      <input
                        type='email'
                        className='
                          text-field-copy
                          w-node-cba6cee5-487b-935c-5668-db5f2f0c653a-868b5c25
                          w-input
                        '
                        autoFocus={true}
                        maxLength='256'
                        name='Email-2'
                        data-name='Email 2'
                        placeholder='Email'
                        id='Email-2'
                        required
                        value={email.trim()}
                        onChange={e => setEmail(e.target.value.trim())}
                      />
                      <input
                        type='submit'
                        value='Continue'
                        data-wait='Please wait...'
                        id='w-node-cba6cee5-487b-935c-5668-db5f2f0c653b-868b5c25'
                        className='button submit-copy w-button'
                        disabled={loggingIn}
                      />
                    </form>
                  </div>
                  <div className='div-block-925'>
                    <div className='div-block-926'></div>
                    <div className='text-block-50-copy cent'>
                      or
                      <br />
                    </div>
                    <div className='div-block-926'></div>
                  </div>
                  <a href='#' className='button-8 whcb w-inline-block'>
                    <img
                      src='/assets/images/icons8-google-48_1icons8-google-48.png'
                      loading='lazy'
                      width='24'
                      alt=''
                      className='image-295'
                    />
                    <button
                      disabled={loggingIn}
                      onClick={async e => {
                        e.preventDefault();
                        await socialLogin('google');
                      }}
                    >
                      Continue with Google
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
