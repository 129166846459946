import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';

function HomeNavBar() {
  return (
    <nav
      role='navigation'
      className='bg-transparent flex justify-between py-4 px-6 fixed top-0 w-full'
    >
      <Link to='/'>
        <Logo className='w-11 h-11' />
      </Link>
      <div className='flex items-center space-x-4'>
        <Link
          to='/login'
          className='bg-transparent py-3 px-5 hover:text-[#2cc1ff] opacity-75 font-semibold'
        >
          Sign In
        </Link>
        <Link
          to='/login'
          className='bg-[#34383a] py-2.5 px-6 rounded-full font-semibold transform transition duration-300 hover:translate-y-[-3px] hover:bg-[#0c77f8] hover:shadow-sky-blue'
        >
          Get Started
        </Link>
      </div>
    </nav>
  );
}

export default HomeNavBar;
