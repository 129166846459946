import classNames from 'classnames';
import PropTypes from 'prop-types';

function MenuItem({ href, active, icon, children, ...rest }) {
  return (
    <li
      className={classNames([
        'nav-category__item',
        active && 'nav-category__item_active',
      ])}
      {...rest}
    >
      <a href={href} className='nav-category__link'>
        {icon}
        <span>{children}</span>
      </a>
    </li>
  );
}

MenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
};

MenuItem.defaultProps = {
  active: false,
};

export default MenuItem;
