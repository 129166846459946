import PropTypes from 'prop-types';
import { BubbleMenu, FloatingMenu } from '@tiptap/react';

const menuTypes = {
  bubble: BubbleMenu,
  floating: FloatingMenu,
};

function Menu({ type, editor, children }) {
  let MenuType = menuTypes[type] || menuTypes.bubble;

  return (
    <MenuType
      className='flex flex-wrap items-center gap-1 bg-gray-100 dark:bg-dark p-2 border-[3px] border-black dark:border-blue rounded-md w-max'
      tippyOptions={{ duration: 100 }}
      editor={editor}
    >
      {children}
    </MenuType>
  );
}

Menu.propTypes = {
  type: PropTypes.string.isRequired,
  editor: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default Menu;
