import tippy from 'tippy.js';
import PropTypes from 'prop-types';
import Placeholder from '@tiptap/extension-placeholder';
import { useEditor, ReactRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Image } from '@tiptap/extension-image';
import { Link } from '@tiptap/extension-link';
import { Mention } from '@tiptap/extension-mention';
import { createContext, useEffect } from 'react';
import TableOfContents from 'components/Editor/TableOfContents';
// import { MentionList } from 'components/SuggestionList';
import SuggestionList from 'components/SuggestionList';

const EditorContext = createContext();

export function EditorProvider({ children }) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Image,
      Link,
      TableOfContents,
      Placeholder.configure({
        placeholder: 'Write something …',
      }),
      Mention.configure({
        suggestion: {
          // allowSpaces: true,
          items: () => {
            // results will be returned in the SuggestionList component
            return [];
          },
          render: () => {
            let reactRenderer;
            let popup;

            return {
              onStart: props => {
                // reactRenderer = new ReactRenderer(MentionList, {
                //   props,
                //   editor: props.editor,
                // });

                reactRenderer = new ReactRenderer(SuggestionList, {
                  props,
                  editor: props.editor,
                });

                popup = tippy('body', {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'bottom-start',
                  maxWidth: '480px',
                });
              },
              onUpdate(props) {
                reactRenderer.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },
              onKeyDown(props) {
                // eslint-disable-next-line react/prop-types
                console.log(props.event);
                // TODO: tackle the keyboed accessibility later
                return reactRenderer.ref?.onKeyDown(props);
              },
              onExit() {
                popup[0].destroy();
                reactRenderer.destroy();
              },
            };
          },
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: 'focus:outline-none',
      },
    },
  });

  // destroy editor on unmount
  useEffect(() => {
    return () => {
      editor && editor.destroy();
    };
    // eslint-disable-next-line
  }, []);

  function toggleTableOfContents() {
    const contents = document.querySelector('.ProseMirror .contents');
    if (contents) {
      contents.parentElement.remove();
      return;
    }

    let tocInserted = false;
    let foundHeadings = false;

    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === 'heading') {
        !tocInserted &&
          editor.commands.insertContentAt({ from: pos }, '<toc></toc>');
        tocInserted = true;
        foundHeadings = true;
      }
    });

    !foundHeadings &&
      alert('Please add headings first to create table of content.');
  }

  return (
    <EditorContext.Provider value={{ editor, toggleTableOfContents }}>
      {children}
    </EditorContext.Provider>
  );
}

EditorProvider.propTypes = {
  children: PropTypes.node.isRequired,
  clientRect: PropTypes.func,
  editor: PropTypes.object,
};

export default EditorContext;
