import { APP_URL } from 'app.config';
import { magic } from 'magiclink';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    loadingUser: true,
    isLoggedIn: false,
    user: null,
  });

  useEffect(() => {
    (async () => {
      const isLoggedIn = await magic.user.isLoggedIn();

      if (isLoggedIn) {
        const user = await magic.user.getMetadata();

        return setAuth(auth => ({
          ...auth,
          user,
          loadingUser: false,
          isLoggedIn: true,
        }));
      } else {
        setAuth(auth => ({
          ...auth,
          loadingUser: false,
        }));
      }
    })();
  }, []);

  async function login(email) {
    await magic.auth.loginWithMagicLink({ email });

    const user = await magic.user.getMetadata();

    setAuth(auth => ({
      ...auth,
      user,
      isLoggedIn: true,
    }));
  }

  async function socialLogin(network) {
    await magic.oauth.loginWithRedirect({
      provider: network /* 'google', 'facebook', 'apple', or 'github' */,
      redirectURI: `${APP_URL}/oauth/callback`,
      scope: ['https://www.googleapis.com/auth/userinfo.email'] /* optional */,
    });
  }

  async function logout() {
    await magic.user.logout();

    setAuth(auth => ({
      ...auth,
      user: null,
      isLoggedIn: false,
    }));
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        socialLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
