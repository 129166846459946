import { db } from 'firebase/init';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

const FoldersContext = createContext();

export function useFolders() {
  return useContext(FoldersContext);
}

export function FoldersProvider({ children }) {
  const [folders, setFolders] = useState({
    activeFolder: null,
    allFolders: [],
  });

  const {
    auth: { user, isLoggedIn },
  } = useAuth();

  // clear state on logout
  useEffect(() => {
    if (!isLoggedIn) {
      setFolders({
        activeFolder: null,
        allFolders: [],
      });
    }
  }, [isLoggedIn]);

  async function createNewFolder(folderId = null) {
    const newFolderData = {
      name: 'Untitled Folder',
      parentFolder: folderId,
      userId: user.publicAddress,
    };

    const folder = await db.collection('folders').add(newFolderData);

    const newFolder = {
      id: folder.id,
      ...newFolderData,
    };

    setFolders(folders => ({
      ...folders,
      allFolders: [...folders.allFolders, newFolder],
    }));

    return newFolder;
  }

  async function renameFolder(id, name) {
    if (!id) return;

    db.collection('folders').doc(id).update({ name });

    setFolders(folders => ({
      ...folders,
      allFolders: folders.allFolders.map(item => {
        if (item.id === id) {
          return {
            ...item,
            name,
          };
        }
        return item;
      }),
    }));
  }

  async function getAllFolders() {
    const folders = await db
      .collection('folders')
      .where('userId', '==', user.publicAddress)
      .get();

    const mappedFolders = [];
    folders.forEach(folder => {
      mappedFolders.push({
        id: folder.id,
        ...folder.data(),
      });
    });

    setFolders(folders => ({
      ...folders,
      allFolders: mappedFolders,
    }));
  }

  function setActiveFolder(folder) {
    setFolders(folders => ({
      ...folders,
      activeFolder: folder,
    }));
  }

  async function deleteFolder(folderId) {
    if (!folderId) return;

    await db.collection('folders').doc(folderId).delete();

    const allFolders = folders.allFolders.filter(f => f.id !== folderId);

    setFolders(folders => ({ ...folders, allFolders }));

    if(folders.activeFolder.id === folderId) {
      setActiveFolder(folders.allFolders[0] || null);
    }
  }

  return (
    <FoldersContext.Provider
      value={{
        folders,
        createNewFolder,
        renameFolder,
        getAllFolders,
        setActiveFolder,
        deleteFolder,
      }}
    >
      {children}
    </FoldersContext.Provider>
  );
}

FoldersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FoldersContext;
