import PropTypes from 'prop-types';

function Error({ code, message }) {
  return (
    <div className='flex w-screen h-screen justify-center items-center'>
      <span className='text-gray-500'>{code}</span>
      <div className='w-0.5 h-6 mx-4 bg-gray-600'></div>
      <h1 className='text-gray-500'>{message}</h1>
    </div>
  );
}

Error.propTypes = {
  code: PropTypes.number,
  message: PropTypes.string.isRequired,
};

export default Error;
