import { db } from 'firebase/init';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from 'context/AuthContext';
import { useFolders } from 'context/FoldersContext';
import { nanoid } from 'utils';

const PagesContext = createContext();

export function usePages() {
  return useContext(PagesContext);
}

export function PagesProvider({ children }) {
  const [pages, setPages] = useState({
    activePage: null,
    allPages: [],
    activeInfobox: null,
  });

  function setPageState(data = {}) {
    setPages(pages => ({
      ...pages,
      ...data,
    }));
  }

  const { createNewFolder, setActiveFolder, getAllFolders } = useFolders();

  const {
    auth: { isLoggedIn, user },
  } = useAuth();

  // clear state on logout
  useEffect(() => {
    if (!isLoggedIn) {
      setPages({
        activePage: null,
        allPages: [],
      });
    }
  }, [isLoggedIn]);

  async function getFirstPage() {
    const pages = await db
      .collection('pages')
      .where('userId', '==', user.publicAddress)
      .limit(1)
      .get();

    const mappedPages = [];
    pages.forEach(page => {
      mappedPages.push({
        id: page.id,
        ...page.data(),
      });
    });

    if (mappedPages.length) {
      setActivePage(mappedPages[0]);
    } else {
      const folder = await createNewFolder();
      setActiveFolder(folder);

      const newPage = await createNewPage(folder.id);
      setPages(pages => ({
        ...pages,
        activePage: newPage,
        allPages: [newPage],
      }));
    }
  }

  async function getPagesByFolder(folderId) {
    if (!folderId) return;

    const pages = await db
      .collection('pages')
      .where('parentFolder', '==', folderId)
      .get();

    const mappedPages = [];
    pages.forEach(page => {
      mappedPages.push({
        id: page.id,
        ...page.data(),
      });
    });

    return mappedPages;
  }

  async function getAllPages() {
    const pages = await db
      .collection('pages')
      .where('userId', '==', user.publicAddress)
      .get();

    const mappedPages = [];
    pages.forEach(page => {
      mappedPages.push({
        id: page.id,
        ...page.data(),
      });
    });

    setAllPages(mappedPages);
  }

  async function createNewPage(folderId = null, title = 'Untitled page') {
    const newPageData = {
      title,
      content: '',
      textContent: '',
      parentFolder: folderId,
      userId: user.publicAddress,
      hasInfoBox: false,
    };

    const page = await db.collection('pages').add(newPageData);

    const newPage = {
      id: page.id,
      ...newPageData,
    };

    return newPage;
  }

  async function deletePage(pageId) {
    if (!pageId) return;

    await db.collection('pages').doc(pageId).delete();

    const allPages = pages.allPages.filter(p => p.id !== pageId);

    setAllPages(allPages);
  }

  async function addPage(folderId, history) {
    const page = await createNewPage(folderId);

    setPages(pages => ({
      ...pages,
      allPages: [...pages.allPages, page],
    }));

    setActivePage(page);

    history.push(`/p/${page.id}`);
  }

  async function getPageById(pageId, history) {
    if (!pageId || pages.activePage) return;

    const page = await db.collection('pages').doc(pageId).get();

    // redirect if user is not an owner
    if (page.data()?.userId !== user.publicAddress) {
      return history.push('/page-not-found');
    }

    setActivePage({
      id: page.id,
      ...page.data(),
    });
  }

  async function loadInitialPageData(pageId, history, shared = false) {
    if (!shared) {
      getAllFolders();
      getAllPages();
    }

    if (!pageId) return;

    let activePage = pages.activePage;

    if (!activePage || activePage.id !== pageId) {
      // load active page if not set
      const page = await db.collection('pages').doc(pageId).get();

      if (!page) return history.push('/page-not-found');

      activePage = {
        id: page.id,
        ...page.data(),
      };

      setActivePage(activePage);
    }

    // redirect if user is not an author
    if (!shared && activePage?.userId !== user.publicAddress) {
      return history.push('/page-not-found');
    }

    if (activePage.hasInfoBox) {
      const activebox = await getInfoBox(activePage.id);
      setPageState({
        activeInfobox: activebox,
      });
    }
  }

  function setAllPages(allPages) {
    setPages(pages => ({
      ...pages,
      allPages,
    }));
  }

  function setActivePage(page) {
    setPages(pages => ({
      ...pages,
      activePage: page,
    }));
  }

  function savePageContent({ title, subTitle, content, pageId }) {
    if (!pageId) return;

    db.collection('pages').doc(pageId).update({
      title,
      subTitle,
      content,
    });

    setPages(pages => ({
      ...pages,
      allPages: pages.allPages.map(page => {
        if (page.id === pageId) {
          return {
            ...page,
            title,
            content,
          };
        }
        return page;
      }),
    }));
  }

  async function addInfoBox() {
    const { allPages, activePage } = pages;
    if (activePage.hasInfoBox) return;

    const newInfobox = {
      pageId: activePage.id,
      title: 'Infobox title',
      subTitle: 'Infobox description',
      backgroundUrl: '/assets/img/default-infobox-image.jpeg',
      data: [
        {
          id: nanoid(),
          title: 'Item Title',
          value: 'Click on title or value to edit',
          type: 'item',
        },
      ],
    };

    const mappedPages = allPages.map(page => ({
      ...page,
      hasInfoBox: page.id === activePage.id,
    }));

    await db.collection('pages').doc(activePage.id).update({
      hasInfoBox: true,
    });
    await db.collection('infoboxes').add(newInfobox);

    setPageState({
      allPages: mappedPages,
      activePage: mappedPages.find(p => p.id === activePage.id),
      activeInfobox: newInfobox,
    });
  }

  async function getInfoBox(docId) {
    if (!docId) return;

    const infobox = await db
      .collection('infoboxes')
      .where('pageId', '==', docId)
      .limit(1)
      .get();

    let activebox = null;

    infobox.forEach(item => {
      activebox = {
        id: item.id,
        ...item.data(),
      };
    });

    return activebox;
  }

  async function updateInfoBox(
    data = pages.activeInfobox,
    options = {
      async: false,
    }
  ) {
    const { async } = options;

    const docId = data.id;
    const infoBoxRef = db.collection('infoboxes').doc(docId);

    delete data.id;

    if (async) {
      infoBoxRef.set(data);
    } else {
      await infoBoxRef.set(data);
    }

    setPageState({
      activeInfobox: {
        id: docId,
        ...data,
      },
    });
  }

  async function deleteInfoBox() {
    if (!confirm('Are you sure, you want to delete the info box?')) return;

    const { allPages, activePage, activeInfobox } = pages;

    const mappedPages = allPages.map(page => ({
      ...page,
      hasInfoBox: page.id === activePage.id ? false : page.hasInfoBox,
    }));

    await db.collection('infoboxes').doc(activeInfobox.id).delete();
    await db.collection('pages').doc(activePage.id).update({
      hasInfoBox: false,
    });

    setPageState({
      allPages: mappedPages,
      activeInfobox: null,
      activePage: mappedPages.find(p => p.id === activePage.id),
    });
  }

  async function sharePage(pageId) {
    if (!pageId) return;

    await db.collection('pages').doc(pageId).update({
      shared: true,
    });
  }

  async function duplicatePage(pageId, activePage, userId) {
    let page = pages.allPages.find(p => p.id === pageId);

    if(activePage) {
      page = activePage;
    }

    if (!page) return;

    const newPage = {
      ...page,
    };

    let newInfobox = null;
    if (newPage.hasInfoBox) {
      newInfobox = await getInfoBox(newPage.id);
    }

    delete newPage.id;
    userId && (newPage.userId = userId);
    
    newInfobox && delete newInfobox.id;

    const pageRes = await db.collection('pages').add(newPage);

    newInfobox && (newInfobox.pageId = pageRes.id);
    newInfobox && (await db.collection('infoboxes').add(newInfobox));

    const addedPage = {
      id: pageRes.id,
      ...newPage,
    };

    setPageState({
      allPages: [...pages.allPages, addedPage],
    });

    return addedPage;
  }

  return (
    <PagesContext.Provider
      value={{
        pages,
        setPageState,
        addPage,
        getFirstPage,
        getPageById,
        loadInitialPageData,
        getPagesByFolder,
        getAllPages,
        createNewPage,
        deletePage,
        setActivePage,
        setAllPages,
        savePageContent,
        getInfoBox,
        addInfoBox,
        updateInfoBox,
        deleteInfoBox,
        sharePage,
        duplicatePage,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
}

PagesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PagesContext;
