import PropTypes from 'prop-types';

function FolderMenu({ children }) {
  return <ul className='nav__list'>{children}</ul>;
}

FolderMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FolderMenu;
