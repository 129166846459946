import PropTypes from 'prop-types';

function MenuItem({ children, active, className, ...rest }) {
  return (
    <button
      className={`p-0.5 hover:bg-black text-white dark:hover:bg-blue rounded-[4px] focus:outline-none ${
        active ? 'bg-black dark:bg-blue' : ''
      } ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}

MenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  className: PropTypes.string,
};

MenuItem.defaultProps = {
  active: false,
  className: '',
};

export default MenuItem;
