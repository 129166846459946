import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DropDown = () => null;

function PageMenuItem({ children, active, href, className, ...rest }) {
  let text = children;
  let dropdown = null;

  if (Array.isArray(children)) {
    text = children.find(child => !child.type);
    dropdown = children.find(child => child.type === DropDown)?.props.children;
  }

  return (
    <li
      className={classNames([
        className,
        'nav-submenu__item',
        active && 'nav-submenu__item_active',
      ])}
      {...rest}
    >
      <Link to={href} className='nav-submenu__link'>
        <svg className='icon icon-document'>
          <use xlinkHref='#icon-document'></use>
        </svg>{' '}
        {text}
      </Link>
      {dropdown}
    </li>
  );
}

PageMenuItem.propTypes = {
  active: PropTypes.bool,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PageMenuItem.defaultProps = {
  active: false,
};

PageMenuItem.DropDown = DropDown;

export default PageMenuItem;
