import { NodeViewWrapper } from '@tiptap/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { buildTocHeadings, updateHeadings } from 'utils/toc';

function TOC({ editor }) {
  const [headings, setHeadings] = useState([]);
  const [showContents, toggleContents] = useState(true);

  useEffect(() => {
    if (editor) {
      renderHeadings();
    } else {
      const allHeadings = buildTocHeadings();
      setHeadings(allHeadings);
    }

    editor?.on('update', renderHeadings);
    return () => {
      editor?.off('update', renderHeadings);
    };
  }, []);

  function renderHeadings() {
    const allHeadings = updateHeadings(editor);

    setHeadings(allHeadings);
  }

  function getHeadings(parentId) {
    return headings.filter(h => h.parentId === parentId);
  }

  function buildHeadingsHtml(heading = null) {
    return (
      <>
        {!!getHeadings(heading ? heading.id : null).length && (
          <ol className={!heading ? 'contents__list' : 'contents__sublist'}>
            {getHeadings(heading ? heading.id : null).map(
              (headingItem, index) => (
                <li
                  className={!heading ? 'contents__item' : 'contents__subitem'}
                  key={index}
                >
                  <a
                    href={`#${headingItem.bookmark}`}
                    className={heading ? 'contents__sublink' : ''}
                  >
                    {headingItem.text}
                  </a>
                  {buildHeadingsHtml(headingItem)}
                </li>
              )
            )}
          </ol>
        )}
      </>
    );
  }

  return (
    <NodeViewWrapper className='contents'>
      <div className='contents__head'>
        <div className='title-m02'>Contents</div>
        <button
          id='btnHideContents'
          className='btn btn-hide'
          onClick={() => toggleContents(!showContents)}
        >
          {showContents ? 'Hide' : 'Show'}
        </button>
      </div>
      <div
        className={classNames([
          'contents__box',
          showContents && 'contents__box_in',
        ])}
      >
        {buildHeadingsHtml()}
      </div>
    </NodeViewWrapper>
  );
}

TOC.propTypes = {
  editor: PropTypes.object,
};

export default TOC;
